import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as api_45accessIiHUWoTbPWMeta } from "/opt/buildhome/repo/pages/account/api-access.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as subscriptionstPsCZnawRQMeta } from "/opt/buildhome/repo/pages/account/subscriptions.vue?macro=true";
import { default as _91slug_93CDA7w8McfPMeta } from "/opt/buildhome/repo/pages/announcements/[slug].vue?macro=true";
import { default as indexlHCjcTvsLZMeta } from "/opt/buildhome/repo/pages/announcements/index.vue?macro=true";
import { default as _91slug_93YWhTatzR1eMeta } from "/opt/buildhome/repo/pages/anode/anode-prices/[slug].vue?macro=true";
import { default as chartJQIy0ZPtVKMeta } from "/opt/buildhome/repo/pages/anode/anode-prices/chart.vue?macro=true";
import { default as tablekexaiAl3AwMeta } from "/opt/buildhome/repo/pages/anode/anode-prices/table.vue?macro=true";
import { default as anode_45priceseprVe2pqTTMeta } from "/opt/buildhome/repo/pages/anode/anode-prices.vue?macro=true";
import { default as indexALekGYzXBoMeta } from "/opt/buildhome/repo/pages/anode/capacity-database/index.vue?macro=true";
import { default as capacity_45databaseDDVx7sFJAGMeta } from "/opt/buildhome/repo/pages/anode/capacity-database.vue?macro=true";
import { default as forecastTORuduzLJ4Meta } from "/opt/buildhome/repo/pages/anode/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentscJhgoqMkZGMeta } from "/opt/buildhome/repo/pages/anode/data-reports/price-assessments.vue?macro=true";
import { default as data_45reportsaa03IQxdrTMeta } from "/opt/buildhome/repo/pages/anode/data-reports.vue?macro=true";
import { default as index7NnI0o2OQnMeta } from "/opt/buildhome/repo/pages/anode/index.vue?macro=true";
import { default as analysislQXLSBhVyIMeta } from "/opt/buildhome/repo/pages/anode/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsim4OOq2YNyMeta } from "/opt/buildhome/repo/pages/anode/market-insights/critical-insights.vue?macro=true";
import { default as videosyA5CfzDIqzMeta } from "/opt/buildhome/repo/pages/anode/market-insights/videos.vue?macro=true";
import { default as market_45insightsr15g35OUhtMeta } from "/opt/buildhome/repo/pages/anode/market-insights.vue?macro=true";
import { default as docs1nOMXHzh0TMeta } from "/opt/buildhome/repo/pages/api/docs.vue?macro=true";
import { default as index0XDxs8O6pDMeta } from "/opt/buildhome/repo/pages/api/index.vue?macro=true";
import { default as appCIzZhzdW1jMeta } from "/opt/buildhome/repo/pages/app.vue?macro=true";
import { default as battery_45emissions_45analyserkyh8ACuK4UMeta } from "/opt/buildhome/repo/pages/battery-emissions-analyser.vue?macro=true";
import { default as _91slug_93fqj5tQyb8nMeta } from "/opt/buildhome/repo/pages/blog-archive/[slug].vue?macro=true";
import { default as indexj1RthwGIxaMeta } from "/opt/buildhome/repo/pages/blog-archive/index.vue?macro=true";
import { default as cathodeAh0CvnfTEwMeta } from "/opt/buildhome/repo/pages/cathode/capacity-database/cathode.vue?macro=true";
import { default as precursorsm9iEml3OvMeta } from "/opt/buildhome/repo/pages/cathode/capacity-database/precursor.vue?macro=true";
import { default as capacity_45databaseepagDY1bqWMeta } from "/opt/buildhome/repo/pages/cathode/capacity-database.vue?macro=true";
import { default as _91slug_93eFOasq8s8uMeta } from "/opt/buildhome/repo/pages/cathode/cathode-prices/[slug].vue?macro=true";
import { default as chartfALDBIpTevMeta } from "/opt/buildhome/repo/pages/cathode/cathode-prices/chart.vue?macro=true";
import { default as tableo3o8t0YWUJMeta } from "/opt/buildhome/repo/pages/cathode/cathode-prices/table.vue?macro=true";
import { default as cathode_45pricesc5J9WQco8GMeta } from "/opt/buildhome/repo/pages/cathode/cathode-prices.vue?macro=true";
import { default as forecastrHZLnzObkeMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/forecast.vue?macro=true";
import { default as market_45assessmentsieGz9RymoqMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/market-assessments.vue?macro=true";
import { default as price_45assessmentsbxDDoL6bMuMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/price-assessments.vue?macro=true";
import { default as data_45reportsz4soLQhz7PMeta } from "/opt/buildhome/repo/pages/cathode/data-reports.vue?macro=true";
import { default as indexqsLC1dgovCMeta } from "/opt/buildhome/repo/pages/cathode/index.vue?macro=true";
import { default as analysisOif6byLY6PMeta } from "/opt/buildhome/repo/pages/cathode/market-insights/analysis.vue?macro=true";
import { default as critical_45insightssfuqggZ78lMeta } from "/opt/buildhome/repo/pages/cathode/market-insights/critical-insights.vue?macro=true";
import { default as videosRuhRDdxBaMMeta } from "/opt/buildhome/repo/pages/cathode/market-insights/videos.vue?macro=true";
import { default as market_45insightsynZTycYkVVMeta } from "/opt/buildhome/repo/pages/cathode/market-insights.vue?macro=true";
import { default as _91slug_93XSeE0LmJSOMeta } from "/opt/buildhome/repo/pages/cobalt/[slug].vue?macro=true";
import { default as _91slug_937kAP5yLvLpMeta } from "/opt/buildhome/repo/pages/cobalt/cobalt-prices/[slug].vue?macro=true";
import { default as chartqqWIfQII1vMeta } from "/opt/buildhome/repo/pages/cobalt/cobalt-prices/chart.vue?macro=true";
import { default as price_45customisation_45toolFENLa2OcGaMeta } from "/opt/buildhome/repo/pages/cobalt/cobalt-prices/price-customisation-tool.vue?macro=true";
import { default as table3WHzffmWkXMeta } from "/opt/buildhome/repo/pages/cobalt/cobalt-prices/table.vue?macro=true";
import { default as cobalt_45priceswxE22xthcOMeta } from "/opt/buildhome/repo/pages/cobalt/cobalt-prices.vue?macro=true";
import { default as forecastm7960tT57FMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentsaatYOREwBcMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/price-assessments.vue?macro=true";
import { default as sustainability2wnSVvYAcxMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/sustainability.vue?macro=true";
import { default as data_45reportsYX5dkMZVztMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports.vue?macro=true";
import { default as indexy2ztjZxwwkMeta } from "/opt/buildhome/repo/pages/cobalt/index.vue?macro=true";
import { default as analysismMkwoAo7ZxMeta } from "/opt/buildhome/repo/pages/cobalt/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsk7JBj1PaFUMeta } from "/opt/buildhome/repo/pages/cobalt/market-insights/critical-insights.vue?macro=true";
import { default as videos7lPzcGJ5LQMeta } from "/opt/buildhome/repo/pages/cobalt/market-insights/videos.vue?macro=true";
import { default as market_45insightsvqIvBH15NLMeta } from "/opt/buildhome/repo/pages/cobalt/market-insights.vue?macro=true";
import { default as battery_45emissions_45analyserh8NIuVa7EAMeta } from "/opt/buildhome/repo/pages/cobalt/sustainability/battery-emissions-analyser.vue?macro=true";
import { default as lcaKFlV3QdApUMeta } from "/opt/buildhome/repo/pages/cobalt/sustainability/lca.vue?macro=true";
import { default as overviewDJ0FrRbyVWMeta } from "/opt/buildhome/repo/pages/cobalt/sustainability/overview.vue?macro=true";
import { default as sustainability_45indexUgYXzZRJMLMeta } from "/opt/buildhome/repo/pages/cobalt/sustainability/sustainability-index.vue?macro=true";
import { default as sustainabilityypxfugLLBRMeta } from "/opt/buildhome/repo/pages/cobalt/sustainability.vue?macro=true";
import { default as consultancypsHirthA55Meta } from "/opt/buildhome/repo/pages/consultancy.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as cookiesZLbBnefQgdMeta } from "/opt/buildhome/repo/pages/cookies.vue?macro=true";
import { default as copper_45weeklyeU2QSPlzKSMeta } from "/opt/buildhome/repo/pages/copper-weekly.vue?macro=true";
import { default as copper_45briefingHX2OSDk0KbMeta } from "/opt/buildhome/repo/pages/copper/data-reports/copper-briefing.vue?macro=true";
import { default as forecastG4u1jWnwjFMeta } from "/opt/buildhome/repo/pages/copper/data-reports/forecast.vue?macro=true";
import { default as data_45reportsAbicDAg94vMeta } from "/opt/buildhome/repo/pages/copper/data-reports.vue?macro=true";
import { default as indexevnKyX3kYRMeta } from "/opt/buildhome/repo/pages/copper/index.vue?macro=true";
import { default as analysisA02BbpVG1nMeta } from "/opt/buildhome/repo/pages/copper/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsbZU1AwakMTMeta } from "/opt/buildhome/repo/pages/copper/market-insights/critical-insights.vue?macro=true";
import { default as videosogZikV3rpWMeta } from "/opt/buildhome/repo/pages/copper/market-insights/videos.vue?macro=true";
import { default as market_45insightsHQCpdOYPa3Meta } from "/opt/buildhome/repo/pages/copper/market-insights.vue?macro=true";
import { default as portaltrua5hnbYaMeta } from "/opt/buildhome/repo/pages/copper/portal.vue?macro=true";
import { default as _91slug_93R0cMrO6ka1Meta } from "/opt/buildhome/repo/pages/downloadform/[slug].vue?macro=true";
import { default as _91slug_93GVUh6co81jMeta } from "/opt/buildhome/repo/pages/events/[slug].vue?macro=true";
import { default as index8VtZ9dOfhKMeta } from "/opt/buildhome/repo/pages/events/africa-day/index.vue?macro=true";
import { default as index3hfMaiHwFbMeta } from "/opt/buildhome/repo/pages/events/battery-day-2020/index.vue?macro=true";
import { default as indexAhgi3MVWPiMeta } from "/opt/buildhome/repo/pages/events/battery-gigafactories-usa-2022/index.vue?macro=true";
import { default as indexKnpHPiboO0Meta } from "/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/index.vue?macro=true";
import { default as locationGURw8TzK7tMeta } from "/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/location.vue?macro=true";
import { default as indexKiVxJV5PbMMeta } from "/opt/buildhome/repo/pages/events/benchmark-lithium-summit-2018/index.vue?macro=true";
import { default as indexdG2i3qSKNDMeta } from "/opt/buildhome/repo/pages/events/benchmark-minerals-summit-2020/index.vue?macro=true";
import { default as indexOirNK1FOQZMeta } from "/opt/buildhome/repo/pages/events/benchmark-week-online-2021/index.vue?macro=true";
import { default as benchmark_45weekChI8CNJA97Meta } from "/opt/buildhome/repo/pages/events/benchmark-week.vue?macro=true";
import { default as agenda9hHorjJAVJMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/agenda.vue?macro=true";
import { default as indexemuFkBpud5Meta } from "/opt/buildhome/repo/pages/events/cathodes-2018/index.vue?macro=true";
import { default as locationPnFVEYxkXwMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/location.vue?macro=true";
import { default as site_45visitsrKHdFPSvgAMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/site-visits.vue?macro=true";
import { default as sponsorsC94iQqFtdXMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/sponsors.vue?macro=true";
import { default as agendaUHWmqLGe1KMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/agenda.vue?macro=true";
import { default as field_45trip1Yrt9bL2tCMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/field-trip.vue?macro=true";
import { default as indexv5BEasyX4OMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/index.vue?macro=true";
import { default as locationkufxPKdRwHMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/location.vue?macro=true";
import { default as sponsors3ZUdUZCKC9Meta } from "/opt/buildhome/repo/pages/events/cathodes-2019/sponsors.vue?macro=true";
import { default as agendaSmOuU4VId7Meta } from "/opt/buildhome/repo/pages/events/cathodes2017/agenda.vue?macro=true";
import { default as indexlXeVAnami2Meta } from "/opt/buildhome/repo/pages/events/cathodes2017/index.vue?macro=true";
import { default as locationPcSdnpU1EcMeta } from "/opt/buildhome/repo/pages/events/cathodes2017/location.vue?macro=true";
import { default as sponsorsQdsSBnieouMeta } from "/opt/buildhome/repo/pages/events/cathodes2017/sponsors.vue?macro=true";
import { default as indexrluBWiKeYKMeta } from "/opt/buildhome/repo/pages/events/ev-festival/index.vue?macro=true";
import { default as indexxLX1JxP2oEMeta } from "/opt/buildhome/repo/pages/events/evfest2021/index.vue?macro=true";
import { default as agendan7ai3oBrWWMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/agenda.vue?macro=true";
import { default as indexeBZslTxfyWMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/index.vue?macro=true";
import { default as locationUBXWu5z3w4Meta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/location.vue?macro=true";
import { default as sponsorsGxZISQnLLAMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/sponsors.vue?macro=true";
import { default as agendauATx9gtMIMMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/agenda.vue?macro=true";
import { default as indexkqUXHfgsTkMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/index.vue?macro=true";
import { default as locationCELUIOsEKyMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/location.vue?macro=true";
import { default as sponsorsH3728K5gwBMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/sponsors.vue?macro=true";
import { default as agendauRTHEEGMJ2Meta } from "/opt/buildhome/repo/pages/events/graphite2016/agenda.vue?macro=true";
import { default as hosts45gc7D6O4zMeta } from "/opt/buildhome/repo/pages/events/graphite2016/hosts.vue?macro=true";
import { default as indexa5HAK56fWkMeta } from "/opt/buildhome/repo/pages/events/graphite2016/index.vue?macro=true";
import { default as locationWVrSPTQaOcMeta } from "/opt/buildhome/repo/pages/events/graphite2016/location.vue?macro=true";
import { default as sponsors3YM88acNxaMeta } from "/opt/buildhome/repo/pages/events/graphite2016/sponsors.vue?macro=true";
import { default as agendasizKnnzGgoMeta } from "/opt/buildhome/repo/pages/events/graphite2017/agenda.vue?macro=true";
import { default as hosts7zOJS72fHKMeta } from "/opt/buildhome/repo/pages/events/graphite2017/hosts.vue?macro=true";
import { default as indexAg3l3jPKLrMeta } from "/opt/buildhome/repo/pages/events/graphite2017/index.vue?macro=true";
import { default as locationhghJmO0K2uMeta } from "/opt/buildhome/repo/pages/events/graphite2017/location.vue?macro=true";
import { default as sponsorsKFob4dGsq5Meta } from "/opt/buildhome/repo/pages/events/graphite2017/sponsors.vue?macro=true";
import { default as indexjDV0ZxvnYzMeta } from "/opt/buildhome/repo/pages/events/index.vue?macro=true";
import { default as indexO3p5kJCN3XMeta } from "/opt/buildhome/repo/pages/events/nickel-day/index.vue?macro=true";
import { default as pastdZ7VYmohzxMeta } from "/opt/buildhome/repo/pages/events/past.vue?macro=true";
import { default as summitsl3ECOjYgVIMeta } from "/opt/buildhome/repo/pages/events/summits.vue?macro=true";
import { default as webinars4SL1J08xY7Meta } from "/opt/buildhome/repo/pages/events/webinars.vue?macro=true";
import { default as chicagoM74ZlfAJbUMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/chicago.vue?macro=true";
import { default as germanySJq8Q36mFzMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/germany.vue?macro=true";
import { default as hong_45kongTQk75pnWK0Meta } from "/opt/buildhome/repo/pages/events/world-tour-2018/hong-kong.vue?macro=true";
import { default as indexPT89hYr8YKMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/index.vue?macro=true";
import { default as london_45day_452cWbLEiZ5nvMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/london-day-2.vue?macro=true";
import { default as london7ChDLfTfWxMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/london.vue?macro=true";
import { default as melbourneT7E4JIazV3Meta } from "/opt/buildhome/repo/pages/events/world-tour-2018/melbourne.vue?macro=true";
import { default as new_45yorkprtxowcLJjMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/new-york.vue?macro=true";
import { default as perthGc7CE39tSfMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/perth.vue?macro=true";
import { default as san_45franciscocH2NNiZH0XMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/san-francisco.vue?macro=true";
import { default as seoulFnqRpbqH6tMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/seoul.vue?macro=true";
import { default as sydney06rRugV6OeMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/sydney.vue?macro=true";
import { default as tokyocP7lXBa7lNMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/tokyo.vue?macro=true";
import { default as torontogKaZRft0c7Meta } from "/opt/buildhome/repo/pages/events/world-tour-2018/toronto.vue?macro=true";
import { default as vancouverMZyyt0esXWMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/vancouver.vue?macro=true";
import { default as adelaideR2rMMhpq6jMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/adelaide.vue?macro=true";
import { default as cape_45townb52IZ4pl4RMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/cape-town.vue?macro=true";
import { default as chicagoUAPaZN6QuaMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/chicago.vue?macro=true";
import { default as germany9f0rSNhBfqMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/germany.vue?macro=true";
import { default as hong_45kongDUAJAHEzLeMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/hong-kong.vue?macro=true";
import { default as index09VnTIbVHsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/index.vue?macro=true";
import { default as londonXenQWS9R8DMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/london.vue?macro=true";
import { default as melbourneE3lG8itTHLMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/melbourne.vue?macro=true";
import { default as new_45york_4523HKX016YpsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/new-york-2.vue?macro=true";
import { default as new_45yorkHT8QEvFbJsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/new-york.vue?macro=true";
import { default as perthkXd6b7Wv3cMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/perth.vue?macro=true";
import { default as san_45franciscosbecrOqESIMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/san-francisco.vue?macro=true";
import { default as seoul9gF3KYp5MWMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/seoul.vue?macro=true";
import { default as sydneyagDin0cwbsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/sydney.vue?macro=true";
import { default as tokyoyC9gASokS7Meta } from "/opt/buildhome/repo/pages/events/world-tour-2019/tokyo.vue?macro=true";
import { default as torontoF7XV1iAyErMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/toronto.vue?macro=true";
import { default as vancouverFyzqDVsgDqMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/vancouver.vue?macro=true";
import { default as indexx7RvgwAwfIMeta } from "/opt/buildhome/repo/pages/events/world-tour-2020/index.vue?macro=true";
import { default as _91slug_93wQbO1ETjfAMeta } from "/opt/buildhome/repo/pages/events/world-tour-2022/[slug].vue?macro=true";
import { default as indexiWbL6E8oiBMeta } from "/opt/buildhome/repo/pages/events/world-tour-2022/index.vue?macro=true";
import { default as indexPe8xFb2JELMeta } from "/opt/buildhome/repo/pages/events/world-tour-2023-new-york/index.vue?macro=true";
import { default as index4L4AO0PDmiMeta } from "/opt/buildhome/repo/pages/events/world-tour-online/index.vue?macro=true";
import { default as canadaCcmwYuB62qMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/canada.vue?macro=true";
import { default as hongkonglFj1vvhgpEMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/hongkong.vue?macro=true";
import { default as indexo8VbWY1V0OMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/index.vue?macro=true";
import { default as londonu3Aw2UjXMGMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/london.vue?macro=true";
import { default as melbourneWaPuiZK7c4Meta } from "/opt/buildhome/repo/pages/events/worldtour-2015/melbourne.vue?macro=true";
import { default as newyork8r4uyKMMS1Meta } from "/opt/buildhome/repo/pages/events/worldtour-2015/newyork.vue?macro=true";
import { default as sydneyv9Qb0JeTdlMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/sydney.vue?macro=true";
import { default as tokyoBm8NvgwWBwMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/tokyo.vue?macro=true";
import { default as torontoFS298skmkbMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/toronto.vue?macro=true";
import { default as canadaTxrt0tqblwMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/canada.vue?macro=true";
import { default as germanyOimOUkCISZMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/germany.vue?macro=true";
import { default as hongkongvnm9Y2ptckMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/hongkong.vue?macro=true";
import { default as indexkXdgSeDR9lMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/index.vue?macro=true";
import { default as londonwh26AgVSEdMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/london.vue?macro=true";
import { default as melbournePgUonFnhy7Meta } from "/opt/buildhome/repo/pages/events/worldtour-2016/melbourne.vue?macro=true";
import { default as newyorkMr6E85Q05nMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/newyork.vue?macro=true";
import { default as siliconFZCwd5sLGNMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/silicon.vue?macro=true";
import { default as sydneyuzz21FymNSMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/sydney.vue?macro=true";
import { default as tokyoJRb6KV8gUgMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/tokyo.vue?macro=true";
import { default as torontoBJlFkEnUisMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/toronto.vue?macro=true";
import { default as washingtonSL0wkLIerqMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/washington.vue?macro=true";
import { default as beijingiIlZHopsJwMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/beijing.vue?macro=true";
import { default as germanyRs6N2ExCq6Meta } from "/opt/buildhome/repo/pages/events/worldtour2017/germany.vue?macro=true";
import { default as hongkongqYAkAGC1WfMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/hongkong.vue?macro=true";
import { default as indexsVNte7bYqVMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/index.vue?macro=true";
import { default as londonaNiUll4LdtMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/london.vue?macro=true";
import { default as melbourneJonPF6wa7KMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/melbourne.vue?macro=true";
import { default as newyork2gBTu9KJiLMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/newyork.vue?macro=true";
import { default as perthTM9bm9w7W2Meta } from "/opt/buildhome/repo/pages/events/worldtour2017/perth.vue?macro=true";
import { default as seoulJRaI0OFK2EMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/seoul.vue?macro=true";
import { default as sydneyoI9RWMJQRSMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/sydney.vue?macro=true";
import { default as tokyoNCpQz3UbowMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/tokyo.vue?macro=true";
import { default as toronto0UFr3iwlevMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/toronto.vue?macro=true";
import { default as vancouvernBMd3Hw92nMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/vancouver.vue?macro=true";
import { default as washingtondcTWkXLphanMMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/washingtondc.vue?macro=true";
import { default as zurichRmDRgb3h0WMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/zurich.vue?macro=true";
import { default as excel_45add_45inESjHEAIXpnMeta } from "/opt/buildhome/repo/pages/excel-add-in.vue?macro=true";
import { default as forecastANhq5mPVuDMeta } from "/opt/buildhome/repo/pages/fluorspar/data-reports/forecast.vue?macro=true";
import { default as data_45reportsMzyKEIS1t8Meta } from "/opt/buildhome/repo/pages/fluorspar/data-reports.vue?macro=true";
import { default as indexI6YuS0AXdnMeta } from "/opt/buildhome/repo/pages/fluorspar/index.vue?macro=true";
import { default as analysisAZoFVUMDZ5Meta } from "/opt/buildhome/repo/pages/fluorspar/market-insights/analysis.vue?macro=true";
import { default as critical_45insightszkP4z0x5EJMeta } from "/opt/buildhome/repo/pages/fluorspar/market-insights/critical-insights.vue?macro=true";
import { default as videosXyqmLRhqgqMeta } from "/opt/buildhome/repo/pages/fluorspar/market-insights/videos.vue?macro=true";
import { default as market_45insightsnxgGrziInTMeta } from "/opt/buildhome/repo/pages/fluorspar/market-insights.vue?macro=true";
import { default as forecastsWqQWc0MH9zMeta } from "/opt/buildhome/repo/pages/forecasts.vue?macro=true";
import { default as stellantisCOCjzzEt6eMeta } from "/opt/buildhome/repo/pages/forms/stellantis.vue?macro=true";
import { default as independent_45practitioners_45assurance_45reportQ4CTpIwSo1Meta } from "/opt/buildhome/repo/pages/independent-practitioners-assurance-report.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as industry_45reportsXRM6SkO9dRMeta } from "/opt/buildhome/repo/pages/industry-reports.vue?macro=true";
import { default as join_45usKPp119MpARMeta } from "/opt/buildhome/repo/pages/join-us.vue?macro=true";
import { default as lcaXXKTXCZSNiMeta } from "/opt/buildhome/repo/pages/lca.vue?macro=true";
import { default as arnold_45schwarzeneggerluxpsu2ehBMeta } from "/opt/buildhome/repo/pages/lifetime-achievement-award/arnold-schwarzenegger.vue?macro=true";
import { default as index1neWLC8xkAMeta } from "/opt/buildhome/repo/pages/lifetime-achievement-award/index.vue?macro=true";
import { default as jb_45straubelt5IwifpSH6Meta } from "/opt/buildhome/repo/pages/lifetime-achievement-award/jb-straubel.vue?macro=true";
import { default as stanley_45whittinghamwsfF0xBNf4Meta } from "/opt/buildhome/repo/pages/lifetime-achievement-award/stanley-whittingham.vue?macro=true";
import { default as gigafactory6R3k32eop2Meta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database/gigafactory.vue?macro=true";
import { default as tier_45rankingp3i6be5PxEMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database/tier-ranking.vue?macro=true";
import { default as capacity_45database4XWL1IPNXRMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database.vue?macro=true";
import { default as forecastqJqCCqGpboMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/forecast.vue?macro=true";
import { default as market_45assessments1sIFH3OSZtMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/market-assessments.vue?macro=true";
import { default as price_45assessmentsemMc3ExvwzMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/price-assessments.vue?macro=true";
import { default as data_45reportslRnERUA3GbMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports.vue?macro=true";
import { default as indexGxu7xHcGAPMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/index.vue?macro=true";
import { default as _91slug_93fYooskxAB4Meta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/[slug].vue?macro=true";
import { default as chartrkMS17oaoqMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/chart.vue?macro=true";
import { default as tableP98W82XPNCMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/table.vue?macro=true";
import { default as lithium_45ion_45batteries_45prices3v8w7O8LfzMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices.vue?macro=true";
import { default as analysis6fx2ZqxOUFMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/analysis.vue?macro=true";
import { default as critical_45insightszvjpko0IHVMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/critical-insights.vue?macro=true";
import { default as videosKpRBhaRWVsMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/videos.vue?macro=true";
import { default as market_45insightsLPvjhaMAtVMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights.vue?macro=true";
import { default as lithium_45ion_45battery_45pricesWg5OajvvIzMeta } from "/opt/buildhome/repo/pages/lithium-ion-battery-prices.vue?macro=true";
import { default as lithium_45ion_45battery_45raw_45material_45indexRIvIeFr6rQMeta } from "/opt/buildhome/repo/pages/lithium-ion-battery-raw-material-index.vue?macro=true";
import { default as _91slug_93TZC7LZQ1hXMeta } from "/opt/buildhome/repo/pages/lithium-price-assessment-portal/[slug].vue?macro=true";
import { default as lithium_45prices0bROrAVXWlMeta } from "/opt/buildhome/repo/pages/lithium-prices.vue?macro=true";
import { default as _91slug_93vPapDA9bxGMeta } from "/opt/buildhome/repo/pages/lithium/[slug].vue?macro=true";
import { default as forecastRCq5T8YQ0kMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentszpc60HlSVUMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/price-assessments.vue?macro=true";
import { default as sustainabilityVwkVBnnOwBMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/sustainability.vue?macro=true";
import { default as data_45reportsGxHknEN00zMeta } from "/opt/buildhome/repo/pages/lithium/data-reports.vue?macro=true";
import { default as index9Oz1g1h8rZMeta } from "/opt/buildhome/repo/pages/lithium/index.vue?macro=true";
import { default as _91slug_93Y9YgWyRJY3Meta } from "/opt/buildhome/repo/pages/lithium/lithium-prices/[slug].vue?macro=true";
import { default as chartNruAdaMsNJMeta } from "/opt/buildhome/repo/pages/lithium/lithium-prices/chart.vue?macro=true";
import { default as price_45customisation_45toolgjhA0gnukAMeta } from "/opt/buildhome/repo/pages/lithium/lithium-prices/price-customisation-tool.vue?macro=true";
import { default as tableEtZNuEenPYMeta } from "/opt/buildhome/repo/pages/lithium/lithium-prices/table.vue?macro=true";
import { default as lithium_45pricesoJNgJQtbnoMeta } from "/opt/buildhome/repo/pages/lithium/lithium-prices.vue?macro=true";
import { default as analysisiVuXCGhfM4Meta } from "/opt/buildhome/repo/pages/lithium/market-insights/analysis.vue?macro=true";
import { default as critical_45insights0suklDEDAdMeta } from "/opt/buildhome/repo/pages/lithium/market-insights/critical-insights.vue?macro=true";
import { default as videosK20aoQCX4aMeta } from "/opt/buildhome/repo/pages/lithium/market-insights/videos.vue?macro=true";
import { default as market_45insightsVcRLh0H9oiMeta } from "/opt/buildhome/repo/pages/lithium/market-insights.vue?macro=true";
import { default as battery_45emissions_45analyserqHSE6GoEkyMeta } from "/opt/buildhome/repo/pages/lithium/sustainability/battery-emissions-analyser.vue?macro=true";
import { default as lca1baWMwQ3l2Meta } from "/opt/buildhome/repo/pages/lithium/sustainability/lca.vue?macro=true";
import { default as overview1BKLQL1obpMeta } from "/opt/buildhome/repo/pages/lithium/sustainability/overview.vue?macro=true";
import { default as sustainability_45indexGd8iYM3BsIMeta } from "/opt/buildhome/repo/pages/lithium/sustainability/sustainability-index.vue?macro=true";
import { default as sustainabilityyQOJaSn3N6Meta } from "/opt/buildhome/repo/pages/lithium/sustainability.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as forecastSXWN7uIPa6Meta } from "/opt/buildhome/repo/pages/manganese/data-reports/forecast.vue?macro=true";
import { default as data_45reportsHxU7ilY92WMeta } from "/opt/buildhome/repo/pages/manganese/data-reports.vue?macro=true";
import { default as index54AsFA2d51Meta } from "/opt/buildhome/repo/pages/manganese/index.vue?macro=true";
import { default as analysis4cdAjG8uYrMeta } from "/opt/buildhome/repo/pages/manganese/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsnGiPn1kpC5Meta } from "/opt/buildhome/repo/pages/manganese/market-insights/critical-insights.vue?macro=true";
import { default as videosURSHwX4Bp4Meta } from "/opt/buildhome/repo/pages/manganese/market-insights/videos.vue?macro=true";
import { default as market_45insights0DAIJSbDzHMeta } from "/opt/buildhome/repo/pages/manganese/market-insights.vue?macro=true";
import { default as market_45assessmentsLZygAaQAt2Meta } from "/opt/buildhome/repo/pages/market-assessments.vue?macro=true";
import { default as _91slug_93K5vELg6AahMeta } from "/opt/buildhome/repo/pages/membership/[slug].vue?macro=true";
import { default as indexbgbFWcyi4kMeta } from "/opt/buildhome/repo/pages/methodologies/index.vue?macro=true";
import { default as gigafactoriesNXWIlegKvVMeta } from "/opt/buildhome/repo/pages/methodologies/market-assessments/gigafactories.vue?macro=true";
import { default as mineral_45pricing_45chartOm1MadzOs4Meta } from "/opt/buildhome/repo/pages/mineral-pricing-chart.vue?macro=true";
import { default as _91slug_93grrjy4huDcMeta } from "/opt/buildhome/repo/pages/natural-graphite/[slug].vue?macro=true";
import { default as forecastE0eKSUqgSPMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentspy1DrObgIQMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/price-assessments.vue?macro=true";
import { default as sustainability6RjQuZPTfNMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/sustainability.vue?macro=true";
import { default as data_45reportslJRQJ51gCqMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports.vue?macro=true";
import { default as indexeD23UhSKGEMeta } from "/opt/buildhome/repo/pages/natural-graphite/index.vue?macro=true";
import { default as analysissxHhPO6IVfMeta } from "/opt/buildhome/repo/pages/natural-graphite/market-insights/analysis.vue?macro=true";
import { default as critical_45insights0hzmDC5RKEMeta } from "/opt/buildhome/repo/pages/natural-graphite/market-insights/critical-insights.vue?macro=true";
import { default as videos7sjDB4ms8JMeta } from "/opt/buildhome/repo/pages/natural-graphite/market-insights/videos.vue?macro=true";
import { default as market_45insightslFNS20MKUjMeta } from "/opt/buildhome/repo/pages/natural-graphite/market-insights.vue?macro=true";
import { default as _91slug_936v79G5C5CoMeta } from "/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/[slug].vue?macro=true";
import { default as chartJl4d9iMqQ5Meta } from "/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/chart.vue?macro=true";
import { default as price_45customisation_45toolYRjhwMNB9FMeta } from "/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/price-customisation-tool.vue?macro=true";
import { default as tableSYS8ZHlfG9Meta } from "/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/table.vue?macro=true";
import { default as natural_45graphite_45pricessQl1MgpTw4Meta } from "/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices.vue?macro=true";
import { default as battery_45emissions_45analyserA0TmA3ToASMeta } from "/opt/buildhome/repo/pages/natural-graphite/sustainability/battery-emissions-analyser.vue?macro=true";
import { default as lcayJ93adE6EXMeta } from "/opt/buildhome/repo/pages/natural-graphite/sustainability/lca.vue?macro=true";
import { default as overviewYPXHqoKrEvMeta } from "/opt/buildhome/repo/pages/natural-graphite/sustainability/overview.vue?macro=true";
import { default as sustainability_45indexLG7UIoyba4Meta } from "/opt/buildhome/repo/pages/natural-graphite/sustainability/sustainability-index.vue?macro=true";
import { default as sustainabilityXbLOqMJbWnMeta } from "/opt/buildhome/repo/pages/natural-graphite/sustainability.vue?macro=true";
import { default as newsletter_45signupdnjg7fnChUMeta } from "/opt/buildhome/repo/pages/newsletter-signup.vue?macro=true";
import { default as newslettersptGM0uLI3kMeta } from "/opt/buildhome/repo/pages/newsletters.vue?macro=true";
import { default as _91slug_93JpK6pyKRngMeta } from "/opt/buildhome/repo/pages/nickel/[slug].vue?macro=true";
import { default as forecastSc7LjxzPGHMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentssSr9cu60pqMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/price-assessments.vue?macro=true";
import { default as sustainabilityg5BOA4u2qwMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/sustainability.vue?macro=true";
import { default as data_45reportsbKe6vcRWGEMeta } from "/opt/buildhome/repo/pages/nickel/data-reports.vue?macro=true";
import { default as indexkC9jVE9k93Meta } from "/opt/buildhome/repo/pages/nickel/index.vue?macro=true";
import { default as analysisEy1ONyb1CgMeta } from "/opt/buildhome/repo/pages/nickel/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsxk7SZa2W9pMeta } from "/opt/buildhome/repo/pages/nickel/market-insights/critical-insights.vue?macro=true";
import { default as videos31rU4NR7HaMeta } from "/opt/buildhome/repo/pages/nickel/market-insights/videos.vue?macro=true";
import { default as market_45insightsDKIOdgEtMnMeta } from "/opt/buildhome/repo/pages/nickel/market-insights.vue?macro=true";
import { default as _91slug_93JCadwomZa0Meta } from "/opt/buildhome/repo/pages/nickel/nickel-prices/[slug].vue?macro=true";
import { default as chartOUB5I7lkOQMeta } from "/opt/buildhome/repo/pages/nickel/nickel-prices/chart.vue?macro=true";
import { default as price_45customisation_45toolbLB6luE5HXMeta } from "/opt/buildhome/repo/pages/nickel/nickel-prices/price-customisation-tool.vue?macro=true";
import { default as table6KxgAeEgwFMeta } from "/opt/buildhome/repo/pages/nickel/nickel-prices/table.vue?macro=true";
import { default as nickel_45pricesb8FEzDSiQaMeta } from "/opt/buildhome/repo/pages/nickel/nickel-prices.vue?macro=true";
import { default as battery_45emissions_45analyseruAqkfXGkW7Meta } from "/opt/buildhome/repo/pages/nickel/sustainability/battery-emissions-analyser.vue?macro=true";
import { default as lca3QtffbfERRMeta } from "/opt/buildhome/repo/pages/nickel/sustainability/lca.vue?macro=true";
import { default as overviewtHfr9CJVNNMeta } from "/opt/buildhome/repo/pages/nickel/sustainability/overview.vue?macro=true";
import { default as sustainability_45indexToy4BiPrRLMeta } from "/opt/buildhome/repo/pages/nickel/sustainability/sustainability-index.vue?macro=true";
import { default as sustainabilitywNFh9CgCZ8Meta } from "/opt/buildhome/repo/pages/nickel/sustainability.vue?macro=true";
import { default as forecastvVUVsoKjXQMeta } from "/opt/buildhome/repo/pages/phosphate/data-reports/forecast.vue?macro=true";
import { default as data_45reportsiUl62WbCYHMeta } from "/opt/buildhome/repo/pages/phosphate/data-reports.vue?macro=true";
import { default as indexKVnlTIdCXzMeta } from "/opt/buildhome/repo/pages/phosphate/index.vue?macro=true";
import { default as analysisaeLDp3ipaVMeta } from "/opt/buildhome/repo/pages/phosphate/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsBtA6lVihkGMeta } from "/opt/buildhome/repo/pages/phosphate/market-insights/critical-insights.vue?macro=true";
import { default as videoswkSVxtmCNQMeta } from "/opt/buildhome/repo/pages/phosphate/market-insights/videos.vue?macro=true";
import { default as market_45insightsU2ZmCp9037Meta } from "/opt/buildhome/repo/pages/phosphate/market-insights.vue?macro=true";
import { default as policy_45newsletter4ngWgXL5sxMeta } from "/opt/buildhome/repo/pages/policy-newsletter.vue?macro=true";
import { default as _91id_93h4oibBAr7rMeta } from "/opt/buildhome/repo/pages/preview/_postType/[id].vue?macro=true";
import { default as _91id_93dA2eCpime3Meta } from "/opt/buildhome/repo/pages/price-assessments/[slug]/[id].vue?macro=true";
import { default as _91id_935gwX0FavvDMeta } from "/opt/buildhome/repo/pages/price-assessments/[slug]/reports/[id].vue?macro=true";
import { default as all_45prices_45subscriptionaoPbdtBLYAMeta } from "/opt/buildhome/repo/pages/price-assessments/all-prices-subscription.vue?macro=true";
import { default as indexVnchHRinAMMeta } from "/opt/buildhome/repo/pages/price-assessments/index.vue?macro=true";
import { default as chart_45builderuwfe2Hcji0Meta } from "/opt/buildhome/repo/pages/price-dashboard/chart-builder.vue?macro=true";
import { default as index8vsS0KUWJUMeta } from "/opt/buildhome/repo/pages/price-dashboard/index.vue?macro=true";
import { default as _91id_93CTA9r8Rz0oMeta } from "/opt/buildhome/repo/pages/price-dashboard/watchlist/[id].vue?macro=true";
import { default as indexxwCInpLYwrMeta } from "/opt/buildhome/repo/pages/price-dashboard/watchlist/index.vue?macro=true";
import { default as price_45spotlight_45signupb55AzOHoQBMeta } from "/opt/buildhome/repo/pages/price-spotlight-signup.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as _91slug_93EgPgNvkJrBMeta } from "/opt/buildhome/repo/pages/rare-earths/[slug].vue?macro=true";
import { default as forecastzsmVRGfDMlMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/forecast.vue?macro=true";
import { default as price_45assessments7mH8HSPsqWMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/price-assessments.vue?macro=true";
import { default as sustainabilitygP19FglaXuMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/sustainability.vue?macro=true";
import { default as data_45reportswKxzGmHVzYMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports.vue?macro=true";
import { default as indexgEXpI3bsM6Meta } from "/opt/buildhome/repo/pages/rare-earths/index.vue?macro=true";
import { default as analysisa55aJc5CPEMeta } from "/opt/buildhome/repo/pages/rare-earths/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsF3nDQYHkmVMeta } from "/opt/buildhome/repo/pages/rare-earths/market-insights/critical-insights.vue?macro=true";
import { default as videosqZJSkFERSjMeta } from "/opt/buildhome/repo/pages/rare-earths/market-insights/videos.vue?macro=true";
import { default as market_45insightsp60zkdtGcUMeta } from "/opt/buildhome/repo/pages/rare-earths/market-insights.vue?macro=true";
import { default as _91slug_93pABT8zOdoaMeta } from "/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/[slug].vue?macro=true";
import { default as chartXlHhk0XmfLMeta } from "/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/chart.vue?macro=true";
import { default as tablehpeyNxbRmiMeta } from "/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/table.vue?macro=true";
import { default as rare_45earths_45pricespNHs2ElCduMeta } from "/opt/buildhome/repo/pages/rare-earths/rare-earths-prices.vue?macro=true";
import { default as lcaCJvnJzu3ZRMeta } from "/opt/buildhome/repo/pages/rare-earths/sustainability/lca.vue?macro=true";
import { default as overviewU3D5Etjv8TMeta } from "/opt/buildhome/repo/pages/rare-earths/sustainability/overview.vue?macro=true";
import { default as sustainability_45indexztavm8KfbvMeta } from "/opt/buildhome/repo/pages/rare-earths/sustainability/sustainability-index.vue?macro=true";
import { default as sustainabilityVPTINBZo2mMeta } from "/opt/buildhome/repo/pages/rare-earths/sustainability.vue?macro=true";
import { default as _91slug_93YUXJlKvyz4Meta } from "/opt/buildhome/repo/pages/recycling/[slug].vue?macro=true";
import { default as _91slug_93JmLp2fvxo9Meta } from "/opt/buildhome/repo/pages/recycling/black-mass-prices/[slug].vue?macro=true";
import { default as chartu26Zl8F9BmMeta } from "/opt/buildhome/repo/pages/recycling/black-mass-prices/chart.vue?macro=true";
import { default as tableQScLVhPnKAMeta } from "/opt/buildhome/repo/pages/recycling/black-mass-prices/table.vue?macro=true";
import { default as black_45mass_45pricesU2c9WJ3suFMeta } from "/opt/buildhome/repo/pages/recycling/black-mass-prices.vue?macro=true";
import { default as forecastVrumgZAXVcMeta } from "/opt/buildhome/repo/pages/recycling/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentsv2PXtTXFZCMeta } from "/opt/buildhome/repo/pages/recycling/data-reports/price-assessments.vue?macro=true";
import { default as data_45reports8ZfIHIp9JuMeta } from "/opt/buildhome/repo/pages/recycling/data-reports.vue?macro=true";
import { default as indexYD3lNtJcwpMeta } from "/opt/buildhome/repo/pages/recycling/index.vue?macro=true";
import { default as analysisFoVq0pOYovMeta } from "/opt/buildhome/repo/pages/recycling/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsKOIF7rCMaJMeta } from "/opt/buildhome/repo/pages/recycling/market-insights/critical-insights.vue?macro=true";
import { default as videos1OCGWJMKdsMeta } from "/opt/buildhome/repo/pages/recycling/market-insights/videos.vue?macro=true";
import { default as market_45insights59s9KIFxQnMeta } from "/opt/buildhome/repo/pages/recycling/market-insights.vue?macro=true";
import { default as portal1CTTUZ9t43Meta } from "/opt/buildhome/repo/pages/recycling/portal.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as set_45password7SadfZikENMeta } from "/opt/buildhome/repo/pages/set-password.vue?macro=true";
import { default as forecastDnImJHDPsvMeta } from "/opt/buildhome/repo/pages/silicon-anode/data-reports/forecast.vue?macro=true";
import { default as data_45reportspIkQQG3nn3Meta } from "/opt/buildhome/repo/pages/silicon-anode/data-reports.vue?macro=true";
import { default as indexSsQIUyPOObMeta } from "/opt/buildhome/repo/pages/silicon-anode/index.vue?macro=true";
import { default as analysisfFjDssdNyZMeta } from "/opt/buildhome/repo/pages/silicon-anode/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsPkzHn0MRUuMeta } from "/opt/buildhome/repo/pages/silicon-anode/market-insights/critical-insights.vue?macro=true";
import { default as videos7ybE1CP7uzMeta } from "/opt/buildhome/repo/pages/silicon-anode/market-insights/videos.vue?macro=true";
import { default as market_45insights8fLmpHcPTgMeta } from "/opt/buildhome/repo/pages/silicon-anode/market-insights.vue?macro=true";
import { default as anode1WABbiIJxoMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/anode.vue?macro=true";
import { default as battery_45cellFluaAOUuneMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/battery-cell.vue?macro=true";
import { default as cathodeUZSTxVSGoiMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/cathode.vue?macro=true";
import { default as indexqdcseVYEWFMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/index.vue?macro=true";
import { default as capacity_45databaseW9eQIa5j0OMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database.vue?macro=true";
import { default as forecastPnD2vUj7Y2Meta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/forecast.vue?macro=true";
import { default as market_45assessments03AyLqIZjCMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/market-assessments.vue?macro=true";
import { default as data_45reportsBbGepCpdkrMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports.vue?macro=true";
import { default as indexnvEi1iGIDVMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/index.vue?macro=true";
import { default as analysismOE5565NneMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsXQULMPNrKPMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/critical-insights.vue?macro=true";
import { default as videosku7vm3djuGMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/videos.vue?macro=true";
import { default as market_45insightsNblaA1GbJDMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights.vue?macro=true";
import { default as forecastwx5MEX99CvMeta } from "/opt/buildhome/repo/pages/solid-state/data-reports/forecast.vue?macro=true";
import { default as data_45reportsPTeiplxzoGMeta } from "/opt/buildhome/repo/pages/solid-state/data-reports.vue?macro=true";
import { default as indexmQDcWUWQBFMeta } from "/opt/buildhome/repo/pages/solid-state/index.vue?macro=true";
import { default as analysisa4TItBCGNhMeta } from "/opt/buildhome/repo/pages/solid-state/market-insights/analysis.vue?macro=true";
import { default as critical_45insightsXuq6pPi88WMeta } from "/opt/buildhome/repo/pages/solid-state/market-insights/critical-insights.vue?macro=true";
import { default as videospHi2ROIXHiMeta } from "/opt/buildhome/repo/pages/solid-state/market-insights/videos.vue?macro=true";
import { default as market_45insightswK8e49bOVNMeta } from "/opt/buildhome/repo/pages/solid-state/market-insights.vue?macro=true";
import { default as sustainabilitym3n2ti7KvWMeta } from "/opt/buildhome/repo/pages/sustainability.vue?macro=true";
import { default as _91slug_93FGFJwhQoDNMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/[slug].vue?macro=true";
import { default as forecastNQlqrTF46QMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/forecast.vue?macro=true";
import { default as price_45assessmentseUAR0Zc1Y0Meta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/price-assessments.vue?macro=true";
import { default as sustainabilityD0hZ2dYlxIMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/sustainability.vue?macro=true";
import { default as data_45reportsHJ5JBqGcGIMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports.vue?macro=true";
import { default as indexNhdCvu5I6TMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/index.vue?macro=true";
import { default as analysisyedzzVFySaMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/market-insights/analysis.vue?macro=true";
import { default as critical_45insights7vh1S7JKjlMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/market-insights/critical-insights.vue?macro=true";
import { default as videosq1W2Mhn5iYMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/market-insights/videos.vue?macro=true";
import { default as market_45insightsi47YgZZM2tMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/market-insights.vue?macro=true";
import { default as battery_45emissions_45analyserGcYMdOSE4CMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/sustainability/battery-emissions-analyser.vue?macro=true";
import { default as lca8LcjoYSyjLMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/sustainability/lca.vue?macro=true";
import { default as overview4c4WOE4do1Meta } from "/opt/buildhome/repo/pages/synthetic-graphite/sustainability/overview.vue?macro=true";
import { default as sustainability_45indexuOFd30DanuMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/sustainability/sustainability-index.vue?macro=true";
import { default as sustainabilityM8TtsstPdnMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/sustainability.vue?macro=true";
import { default as _91slug_93oZyP7IPyVAMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/[slug].vue?macro=true";
import { default as chartOF6PPOR8tjMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/chart.vue?macro=true";
import { default as price_45customisation_45tool4o0mFaXBuvMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/price-customisation-tool.vue?macro=true";
import { default as table1zqWvNLyH9Meta } from "/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/table.vue?macro=true";
import { default as synthetic_45graphite_45pricesUBKrsVvzWDMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as _91id_93m3TjEmenjzMeta } from "~/custom-pages/[market]/data-reports/[subCategory]/video/[id].vue?macro=true";
import { default as _91id_93O2fD41Mkw6Meta } from "~/custom-pages/[market]/data-reports/[subCategory]/report/[id].vue?macro=true";
import { default as _91id_93lpzqr6FtInMeta } from "~/custom-pages/[market]/data-reports/report/[id].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "account-api-access",
    path: "/account/api-access",
    meta: api_45accessIiHUWoTbPWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/api-access.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account-subscriptions",
    path: "/account/subscriptions",
    meta: subscriptionstPsCZnawRQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/subscriptions.vue")
  },
  {
    name: "announcements-slug",
    path: "/announcements/:slug()",
    meta: _91slug_93CDA7w8McfPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/announcements/[slug].vue")
  },
  {
    name: "announcements",
    path: "/announcements",
    component: () => import("/opt/buildhome/repo/pages/announcements/index.vue")
  },
  {
    name: "anode-anode-prices",
    path: "/anode/anode-prices",
    meta: anode_45priceseprVe2pqTTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/anode-prices.vue"),
    children: [
  {
    name: "anode-anode-prices-slug",
    path: ":slug()",
    meta: _91slug_93YWhTatzR1eMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/anode-prices/[slug].vue")
  },
  {
    name: "anode-anode-prices-chart",
    path: "chart",
    meta: chartJQIy0ZPtVKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/anode-prices/chart.vue")
  },
  {
    name: "anode-anode-prices-table",
    path: "table",
    meta: tablekexaiAl3AwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/anode-prices/table.vue")
  }
]
  },
  {
    name: capacity_45databaseDDVx7sFJAGMeta?.name,
    path: "/anode/capacity-database",
    meta: capacity_45databaseDDVx7sFJAGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/capacity-database.vue"),
    children: [
  {
    name: "anode-capacity-database",
    path: "",
    meta: indexALekGYzXBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/capacity-database/index.vue")
  }
]
  },
  {
    name: "anode-data-reports",
    path: "/anode/data-reports",
    meta: data_45reportsaa03IQxdrTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports.vue"),
    children: [
  {
    name: "anode-data-reports-forecast",
    path: "forecast",
    meta: forecastTORuduzLJ4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports/forecast.vue")
  },
  {
    name: "anode-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentscJhgoqMkZGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports/price-assessments.vue")
  }
]
  },
  {
    name: "anode",
    path: "/anode",
    meta: index7NnI0o2OQnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/index.vue")
  },
  {
    name: "anode-market-insights",
    path: "/anode/market-insights",
    meta: market_45insightsr15g35OUhtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/market-insights.vue"),
    children: [
  {
    name: "anode-market-insights-analysis",
    path: "analysis",
    meta: analysislQXLSBhVyIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/market-insights/analysis.vue")
  },
  {
    name: "anode-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsim4OOq2YNyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/market-insights/critical-insights.vue")
  },
  {
    name: "anode-market-insights-videos",
    path: "videos",
    meta: videosyA5CfzDIqzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/market-insights/videos.vue")
  }
]
  },
  {
    name: "api-docs",
    path: "/api/docs",
    meta: docs1nOMXHzh0TMeta || {},
    component: () => import("/opt/buildhome/repo/pages/api/docs.vue")
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/opt/buildhome/repo/pages/api/index.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/opt/buildhome/repo/pages/app.vue")
  },
  {
    name: "battery-emissions-analyser",
    path: "/battery-emissions-analyser",
    meta: battery_45emissions_45analyserkyh8ACuK4UMeta || {},
    component: () => import("/opt/buildhome/repo/pages/battery-emissions-analyser.vue")
  },
  {
    name: "blog-archive-slug",
    path: "/blog-archive/:slug()",
    meta: _91slug_93fqj5tQyb8nMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog-archive/[slug].vue")
  },
  {
    name: "blog-archive",
    path: "/blog-archive",
    component: () => import("/opt/buildhome/repo/pages/blog-archive/index.vue")
  },
  {
    name: "cathode-capacity-database",
    path: "/cathode/capacity-database",
    meta: capacity_45databaseepagDY1bqWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/capacity-database.vue"),
    children: [
  {
    name: "cathode-capacity-database-cathode",
    path: "cathode",
    meta: cathodeAh0CvnfTEwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/capacity-database/cathode.vue")
  },
  {
    name: "cathode-capacity-database-precursor",
    path: "precursor",
    meta: precursorsm9iEml3OvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/capacity-database/precursor.vue")
  }
]
  },
  {
    name: "cathode-cathode-prices",
    path: "/cathode/cathode-prices",
    meta: cathode_45pricesc5J9WQco8GMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/cathode-prices.vue"),
    children: [
  {
    name: "cathode-cathode-prices-slug",
    path: ":slug()",
    meta: _91slug_93eFOasq8s8uMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/cathode-prices/[slug].vue")
  },
  {
    name: "cathode-cathode-prices-chart",
    path: "chart",
    meta: chartfALDBIpTevMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/cathode-prices/chart.vue")
  },
  {
    name: "cathode-cathode-prices-table",
    path: "table",
    meta: tableo3o8t0YWUJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/cathode-prices/table.vue")
  }
]
  },
  {
    name: "cathode-data-reports",
    path: "/cathode/data-reports",
    meta: data_45reportsz4soLQhz7PMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports.vue"),
    children: [
  {
    name: "cathode-data-reports-forecast",
    path: "forecast",
    meta: forecastrHZLnzObkeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/forecast.vue")
  },
  {
    name: "cathode-data-reports-market-assessments",
    path: "market-assessments",
    meta: market_45assessmentsieGz9RymoqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/market-assessments.vue")
  },
  {
    name: "cathode-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentsbxDDoL6bMuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/price-assessments.vue")
  }
]
  },
  {
    name: "cathode",
    path: "/cathode",
    meta: indexqsLC1dgovCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/index.vue")
  },
  {
    name: "cathode-market-insights",
    path: "/cathode/market-insights",
    meta: market_45insightsynZTycYkVVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/market-insights.vue"),
    children: [
  {
    name: "cathode-market-insights-analysis",
    path: "analysis",
    meta: analysisOif6byLY6PMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/market-insights/analysis.vue")
  },
  {
    name: "cathode-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightssfuqggZ78lMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/market-insights/critical-insights.vue")
  },
  {
    name: "cathode-market-insights-videos",
    path: "videos",
    meta: videosRuhRDdxBaMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/market-insights/videos.vue")
  }
]
  },
  {
    name: "cobalt-slug",
    path: "/cobalt/:slug()",
    component: () => import("/opt/buildhome/repo/pages/cobalt/[slug].vue")
  },
  {
    name: "cobalt-cobalt-prices",
    path: "/cobalt/cobalt-prices",
    meta: cobalt_45priceswxE22xthcOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/cobalt-prices.vue"),
    children: [
  {
    name: "cobalt-cobalt-prices-slug",
    path: ":slug()",
    meta: _91slug_937kAP5yLvLpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/cobalt-prices/[slug].vue")
  },
  {
    name: "cobalt-cobalt-prices-chart",
    path: "chart",
    meta: chartqqWIfQII1vMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/cobalt-prices/chart.vue")
  },
  {
    name: "cobalt-cobalt-prices-price-customisation-tool",
    path: "price-customisation-tool",
    meta: price_45customisation_45toolFENLa2OcGaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/cobalt-prices/price-customisation-tool.vue")
  },
  {
    name: "cobalt-cobalt-prices-table",
    path: "table",
    meta: table3WHzffmWkXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/cobalt-prices/table.vue")
  }
]
  },
  {
    name: "cobalt-data-reports",
    path: "/cobalt/data-reports",
    meta: data_45reportsYX5dkMZVztMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports.vue"),
    children: [
  {
    name: "cobalt-data-reports-forecast",
    path: "forecast",
    meta: forecastm7960tT57FMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/forecast.vue")
  },
  {
    name: "cobalt-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentsaatYOREwBcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/price-assessments.vue")
  },
  {
    name: "cobalt-data-reports-sustainability",
    path: "sustainability",
    meta: sustainability2wnSVvYAcxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/sustainability.vue")
  }
]
  },
  {
    name: "cobalt",
    path: "/cobalt",
    meta: indexy2ztjZxwwkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/index.vue")
  },
  {
    name: "cobalt-market-insights",
    path: "/cobalt/market-insights",
    meta: market_45insightsvqIvBH15NLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/market-insights.vue"),
    children: [
  {
    name: "cobalt-market-insights-analysis",
    path: "analysis",
    meta: analysismMkwoAo7ZxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/market-insights/analysis.vue")
  },
  {
    name: "cobalt-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsk7JBj1PaFUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/market-insights/critical-insights.vue")
  },
  {
    name: "cobalt-market-insights-videos",
    path: "videos",
    meta: videos7lPzcGJ5LQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/market-insights/videos.vue")
  }
]
  },
  {
    name: "cobalt-sustainability",
    path: "/cobalt/sustainability",
    meta: sustainabilityypxfugLLBRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/sustainability.vue"),
    children: [
  {
    name: "cobalt-sustainability-battery-emissions-analyser",
    path: "battery-emissions-analyser",
    meta: battery_45emissions_45analyserh8NIuVa7EAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/sustainability/battery-emissions-analyser.vue")
  },
  {
    name: "cobalt-sustainability-lca",
    path: "lca",
    meta: lcaKFlV3QdApUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/sustainability/lca.vue")
  },
  {
    name: "cobalt-sustainability-overview",
    path: "overview",
    meta: overviewDJ0FrRbyVWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/sustainability/overview.vue")
  },
  {
    name: "cobalt-sustainability-sustainability-index",
    path: "sustainability-index",
    meta: sustainability_45indexUgYXzZRJMLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/sustainability/sustainability-index.vue")
  }
]
  },
  {
    name: "consultancy",
    path: "/consultancy",
    component: () => import("/opt/buildhome/repo/pages/consultancy.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/opt/buildhome/repo/pages/cookies.vue")
  },
  {
    name: "copper-weekly",
    path: "/copper-weekly",
    component: () => import("/opt/buildhome/repo/pages/copper-weekly.vue")
  },
  {
    name: "copper-data-reports",
    path: "/copper/data-reports",
    meta: data_45reportsAbicDAg94vMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/data-reports.vue"),
    children: [
  {
    name: "copper-data-reports-copper-briefing",
    path: "copper-briefing",
    meta: copper_45briefingHX2OSDk0KbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/data-reports/copper-briefing.vue")
  },
  {
    name: "copper-data-reports-forecast",
    path: "forecast",
    meta: forecastG4u1jWnwjFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/data-reports/forecast.vue")
  }
]
  },
  {
    name: "copper",
    path: "/copper",
    meta: indexevnKyX3kYRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/index.vue")
  },
  {
    name: "copper-market-insights",
    path: "/copper/market-insights",
    meta: market_45insightsHQCpdOYPa3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/market-insights.vue"),
    children: [
  {
    name: "copper-market-insights-analysis",
    path: "analysis",
    meta: analysisA02BbpVG1nMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/market-insights/analysis.vue")
  },
  {
    name: "copper-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsbZU1AwakMTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/market-insights/critical-insights.vue")
  },
  {
    name: "copper-market-insights-videos",
    path: "videos",
    meta: videosogZikV3rpWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/market-insights/videos.vue")
  }
]
  },
  {
    name: "copper-portal",
    path: "/copper/portal",
    meta: portaltrua5hnbYaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/copper/portal.vue")
  },
  {
    name: "downloadform-slug",
    path: "/downloadform/:slug()",
    component: () => import("/opt/buildhome/repo/pages/downloadform/[slug].vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    meta: _91slug_93GVUh6co81jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/[slug].vue")
  },
  {
    name: "events-africa-day",
    path: "/events/africa-day",
    component: () => import("/opt/buildhome/repo/pages/events/africa-day/index.vue")
  },
  {
    name: "events-battery-day-2020",
    path: "/events/battery-day-2020",
    component: () => import("/opt/buildhome/repo/pages/events/battery-day-2020/index.vue")
  },
  {
    name: "events-battery-gigafactories-usa-2022",
    path: "/events/battery-gigafactories-usa-2022",
    component: () => import("/opt/buildhome/repo/pages/events/battery-gigafactories-usa-2022/index.vue")
  },
  {
    name: "events-battery-megafactories-europe-2022",
    path: "/events/battery-megafactories-europe-2022",
    component: () => import("/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/index.vue")
  },
  {
    name: "events-battery-megafactories-europe-2022-location",
    path: "/events/battery-megafactories-europe-2022/location",
    component: () => import("/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/location.vue")
  },
  {
    name: "events-benchmark-lithium-summit-2018",
    path: "/events/benchmark-lithium-summit-2018",
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-lithium-summit-2018/index.vue")
  },
  {
    name: "events-benchmark-minerals-summit-2020",
    path: "/events/benchmark-minerals-summit-2020",
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-minerals-summit-2020/index.vue")
  },
  {
    name: "events-benchmark-week-online-2021",
    path: "/events/benchmark-week-online-2021",
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-week-online-2021/index.vue")
  },
  {
    name: "events-benchmark-week",
    path: "/events/benchmark-week",
    meta: benchmark_45weekChI8CNJA97Meta || {},
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-week.vue")
  },
  {
    name: "events-cathodes-2018-agenda",
    path: "/events/cathodes-2018/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/agenda.vue")
  },
  {
    name: "events-cathodes-2018",
    path: "/events/cathodes-2018",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/index.vue")
  },
  {
    name: "events-cathodes-2018-location",
    path: "/events/cathodes-2018/location",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/location.vue")
  },
  {
    name: "events-cathodes-2018-site-visits",
    path: "/events/cathodes-2018/site-visits",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/site-visits.vue")
  },
  {
    name: "events-cathodes-2018-sponsors",
    path: "/events/cathodes-2018/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/sponsors.vue")
  },
  {
    name: "events-cathodes-2019-agenda",
    path: "/events/cathodes-2019/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/agenda.vue")
  },
  {
    name: "events-cathodes-2019-field-trip",
    path: "/events/cathodes-2019/field-trip",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/field-trip.vue")
  },
  {
    name: "events-cathodes-2019",
    path: "/events/cathodes-2019",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/index.vue")
  },
  {
    name: "events-cathodes-2019-location",
    path: "/events/cathodes-2019/location",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/location.vue")
  },
  {
    name: "events-cathodes-2019-sponsors",
    path: "/events/cathodes-2019/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/sponsors.vue")
  },
  {
    name: "events-cathodes2017-agenda",
    path: "/events/cathodes2017/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/agenda.vue")
  },
  {
    name: "events-cathodes2017",
    path: "/events/cathodes2017",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/index.vue")
  },
  {
    name: "events-cathodes2017-location",
    path: "/events/cathodes2017/location",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/location.vue")
  },
  {
    name: "events-cathodes2017-sponsors",
    path: "/events/cathodes2017/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/sponsors.vue")
  },
  {
    name: "events-ev-festival",
    path: "/events/ev-festival",
    component: () => import("/opt/buildhome/repo/pages/events/ev-festival/index.vue")
  },
  {
    name: "events-evfest2021",
    path: "/events/evfest2021",
    component: () => import("/opt/buildhome/repo/pages/events/evfest2021/index.vue")
  },
  {
    name: "events-graphite-and-anodes-2018-agenda",
    path: "/events/graphite-and-anodes-2018/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/agenda.vue")
  },
  {
    name: "events-graphite-and-anodes-2018",
    path: "/events/graphite-and-anodes-2018",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/index.vue")
  },
  {
    name: "events-graphite-and-anodes-2018-location",
    path: "/events/graphite-and-anodes-2018/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/location.vue")
  },
  {
    name: "events-graphite-and-anodes-2018-sponsors",
    path: "/events/graphite-and-anodes-2018/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/sponsors.vue")
  },
  {
    name: "events-graphite-and-anodes-2019-agenda",
    path: "/events/graphite-and-anodes-2019/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/agenda.vue")
  },
  {
    name: "events-graphite-and-anodes-2019",
    path: "/events/graphite-and-anodes-2019",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/index.vue")
  },
  {
    name: "events-graphite-and-anodes-2019-location",
    path: "/events/graphite-and-anodes-2019/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/location.vue")
  },
  {
    name: "events-graphite-and-anodes-2019-sponsors",
    path: "/events/graphite-and-anodes-2019/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/sponsors.vue")
  },
  {
    name: "events-graphite2016-agenda",
    path: "/events/graphite2016/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/agenda.vue")
  },
  {
    name: "events-graphite2016-hosts",
    path: "/events/graphite2016/hosts",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/hosts.vue")
  },
  {
    name: "events-graphite2016",
    path: "/events/graphite2016",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/index.vue")
  },
  {
    name: "events-graphite2016-location",
    path: "/events/graphite2016/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/location.vue")
  },
  {
    name: "events-graphite2016-sponsors",
    path: "/events/graphite2016/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/sponsors.vue")
  },
  {
    name: "events-graphite2017-agenda",
    path: "/events/graphite2017/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/agenda.vue")
  },
  {
    name: "events-graphite2017-hosts",
    path: "/events/graphite2017/hosts",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/hosts.vue")
  },
  {
    name: "events-graphite2017",
    path: "/events/graphite2017",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/index.vue")
  },
  {
    name: "events-graphite2017-location",
    path: "/events/graphite2017/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/location.vue")
  },
  {
    name: "events-graphite2017-sponsors",
    path: "/events/graphite2017/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/sponsors.vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexjDV0ZxvnYzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/index.vue")
  },
  {
    name: "events-nickel-day",
    path: "/events/nickel-day",
    component: () => import("/opt/buildhome/repo/pages/events/nickel-day/index.vue")
  },
  {
    name: "events-past",
    path: "/events/past",
    meta: pastdZ7VYmohzxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/past.vue")
  },
  {
    name: "events-summits",
    path: "/events/summits",
    meta: summitsl3ECOjYgVIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/summits.vue")
  },
  {
    name: "events-webinars",
    path: "/events/webinars",
    meta: webinars4SL1J08xY7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/events/webinars.vue")
  },
  {
    name: "events-world-tour-2018-chicago",
    path: "/events/world-tour-2018/chicago",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/chicago.vue")
  },
  {
    name: "events-world-tour-2018-germany",
    path: "/events/world-tour-2018/germany",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/germany.vue")
  },
  {
    name: "events-world-tour-2018-hong-kong",
    path: "/events/world-tour-2018/hong-kong",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/hong-kong.vue")
  },
  {
    name: "events-world-tour-2018",
    path: "/events/world-tour-2018",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/index.vue")
  },
  {
    name: "events-world-tour-2018-london-day-2",
    path: "/events/world-tour-2018/london-day-2",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/london-day-2.vue")
  },
  {
    name: "events-world-tour-2018-london",
    path: "/events/world-tour-2018/london",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/london.vue")
  },
  {
    name: "events-world-tour-2018-melbourne",
    path: "/events/world-tour-2018/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/melbourne.vue")
  },
  {
    name: "events-world-tour-2018-new-york",
    path: "/events/world-tour-2018/new-york",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/new-york.vue")
  },
  {
    name: "events-world-tour-2018-perth",
    path: "/events/world-tour-2018/perth",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/perth.vue")
  },
  {
    name: "events-world-tour-2018-san-francisco",
    path: "/events/world-tour-2018/san-francisco",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/san-francisco.vue")
  },
  {
    name: "events-world-tour-2018-seoul",
    path: "/events/world-tour-2018/seoul",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/seoul.vue")
  },
  {
    name: "events-world-tour-2018-sydney",
    path: "/events/world-tour-2018/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/sydney.vue")
  },
  {
    name: "events-world-tour-2018-tokyo",
    path: "/events/world-tour-2018/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/tokyo.vue")
  },
  {
    name: "events-world-tour-2018-toronto",
    path: "/events/world-tour-2018/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/toronto.vue")
  },
  {
    name: "events-world-tour-2018-vancouver",
    path: "/events/world-tour-2018/vancouver",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/vancouver.vue")
  },
  {
    name: "events-world-tour-2019-adelaide",
    path: "/events/world-tour-2019/adelaide",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/adelaide.vue")
  },
  {
    name: "events-world-tour-2019-cape-town",
    path: "/events/world-tour-2019/cape-town",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/cape-town.vue")
  },
  {
    name: "events-world-tour-2019-chicago",
    path: "/events/world-tour-2019/chicago",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/chicago.vue")
  },
  {
    name: "events-world-tour-2019-germany",
    path: "/events/world-tour-2019/germany",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/germany.vue")
  },
  {
    name: "events-world-tour-2019-hong-kong",
    path: "/events/world-tour-2019/hong-kong",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/hong-kong.vue")
  },
  {
    name: "events-world-tour-2019",
    path: "/events/world-tour-2019",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/index.vue")
  },
  {
    name: "events-world-tour-2019-london",
    path: "/events/world-tour-2019/london",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/london.vue")
  },
  {
    name: "events-world-tour-2019-melbourne",
    path: "/events/world-tour-2019/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/melbourne.vue")
  },
  {
    name: "events-world-tour-2019-new-york-2",
    path: "/events/world-tour-2019/new-york-2",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/new-york-2.vue")
  },
  {
    name: "events-world-tour-2019-new-york",
    path: "/events/world-tour-2019/new-york",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/new-york.vue")
  },
  {
    name: "events-world-tour-2019-perth",
    path: "/events/world-tour-2019/perth",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/perth.vue")
  },
  {
    name: "events-world-tour-2019-san-francisco",
    path: "/events/world-tour-2019/san-francisco",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/san-francisco.vue")
  },
  {
    name: "events-world-tour-2019-seoul",
    path: "/events/world-tour-2019/seoul",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/seoul.vue")
  },
  {
    name: "events-world-tour-2019-sydney",
    path: "/events/world-tour-2019/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/sydney.vue")
  },
  {
    name: "events-world-tour-2019-tokyo",
    path: "/events/world-tour-2019/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/tokyo.vue")
  },
  {
    name: "events-world-tour-2019-toronto",
    path: "/events/world-tour-2019/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/toronto.vue")
  },
  {
    name: "events-world-tour-2019-vancouver",
    path: "/events/world-tour-2019/vancouver",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/vancouver.vue")
  },
  {
    name: "events-world-tour-2020",
    path: "/events/world-tour-2020",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2020/index.vue")
  },
  {
    name: "events-world-tour-2022-slug",
    path: "/events/world-tour-2022/:slug()",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2022/[slug].vue")
  },
  {
    name: "events-world-tour-2022",
    path: "/events/world-tour-2022",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2022/index.vue")
  },
  {
    name: "events-world-tour-2023-new-york",
    path: "/events/world-tour-2023-new-york",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2023-new-york/index.vue")
  },
  {
    name: "events-world-tour-online",
    path: "/events/world-tour-online",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-online/index.vue")
  },
  {
    name: "events-worldtour-2015-canada",
    path: "/events/worldtour-2015/canada",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/canada.vue")
  },
  {
    name: "events-worldtour-2015-hongkong",
    path: "/events/worldtour-2015/hongkong",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/hongkong.vue")
  },
  {
    name: "events-worldtour-2015",
    path: "/events/worldtour-2015",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/index.vue")
  },
  {
    name: "events-worldtour-2015-london",
    path: "/events/worldtour-2015/london",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/london.vue")
  },
  {
    name: "events-worldtour-2015-melbourne",
    path: "/events/worldtour-2015/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/melbourne.vue")
  },
  {
    name: "events-worldtour-2015-newyork",
    path: "/events/worldtour-2015/newyork",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/newyork.vue")
  },
  {
    name: "events-worldtour-2015-sydney",
    path: "/events/worldtour-2015/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/sydney.vue")
  },
  {
    name: "events-worldtour-2015-tokyo",
    path: "/events/worldtour-2015/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/tokyo.vue")
  },
  {
    name: "events-worldtour-2015-toronto",
    path: "/events/worldtour-2015/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/toronto.vue")
  },
  {
    name: "events-worldtour-2016-canada",
    path: "/events/worldtour-2016/canada",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/canada.vue")
  },
  {
    name: "events-worldtour-2016-germany",
    path: "/events/worldtour-2016/germany",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/germany.vue")
  },
  {
    name: "events-worldtour-2016-hongkong",
    path: "/events/worldtour-2016/hongkong",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/hongkong.vue")
  },
  {
    name: "events-worldtour-2016",
    path: "/events/worldtour-2016",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/index.vue")
  },
  {
    name: "events-worldtour-2016-london",
    path: "/events/worldtour-2016/london",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/london.vue")
  },
  {
    name: "events-worldtour-2016-melbourne",
    path: "/events/worldtour-2016/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/melbourne.vue")
  },
  {
    name: "events-worldtour-2016-newyork",
    path: "/events/worldtour-2016/newyork",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/newyork.vue")
  },
  {
    name: "events-worldtour-2016-silicon",
    path: "/events/worldtour-2016/silicon",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/silicon.vue")
  },
  {
    name: "events-worldtour-2016-sydney",
    path: "/events/worldtour-2016/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/sydney.vue")
  },
  {
    name: "events-worldtour-2016-tokyo",
    path: "/events/worldtour-2016/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/tokyo.vue")
  },
  {
    name: "events-worldtour-2016-toronto",
    path: "/events/worldtour-2016/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/toronto.vue")
  },
  {
    name: "events-worldtour-2016-washington",
    path: "/events/worldtour-2016/washington",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/washington.vue")
  },
  {
    name: "events-worldtour2017-beijing",
    path: "/events/worldtour2017/beijing",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/beijing.vue")
  },
  {
    name: "events-worldtour2017-germany",
    path: "/events/worldtour2017/germany",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/germany.vue")
  },
  {
    name: "events-worldtour2017-hongkong",
    path: "/events/worldtour2017/hongkong",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/hongkong.vue")
  },
  {
    name: "events-worldtour2017",
    path: "/events/worldtour2017",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/index.vue")
  },
  {
    name: "events-worldtour2017-london",
    path: "/events/worldtour2017/london",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/london.vue")
  },
  {
    name: "events-worldtour2017-melbourne",
    path: "/events/worldtour2017/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/melbourne.vue")
  },
  {
    name: "events-worldtour2017-newyork",
    path: "/events/worldtour2017/newyork",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/newyork.vue")
  },
  {
    name: "events-worldtour2017-perth",
    path: "/events/worldtour2017/perth",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/perth.vue")
  },
  {
    name: "events-worldtour2017-seoul",
    path: "/events/worldtour2017/seoul",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/seoul.vue")
  },
  {
    name: "events-worldtour2017-sydney",
    path: "/events/worldtour2017/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/sydney.vue")
  },
  {
    name: "events-worldtour2017-tokyo",
    path: "/events/worldtour2017/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/tokyo.vue")
  },
  {
    name: "events-worldtour2017-toronto",
    path: "/events/worldtour2017/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/toronto.vue")
  },
  {
    name: "events-worldtour2017-vancouver",
    path: "/events/worldtour2017/vancouver",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/vancouver.vue")
  },
  {
    name: "events-worldtour2017-washingtondc",
    path: "/events/worldtour2017/washingtondc",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/washingtondc.vue")
  },
  {
    name: "events-worldtour2017-zurich",
    path: "/events/worldtour2017/zurich",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/zurich.vue")
  },
  {
    name: "excel-add-in",
    path: "/excel-add-in",
    component: () => import("/opt/buildhome/repo/pages/excel-add-in.vue")
  },
  {
    name: "fluorspar-data-reports",
    path: "/fluorspar/data-reports",
    meta: data_45reportsMzyKEIS1t8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/data-reports.vue"),
    children: [
  {
    name: "fluorspar-data-reports-forecast",
    path: "forecast",
    meta: forecastANhq5mPVuDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/data-reports/forecast.vue")
  }
]
  },
  {
    name: "fluorspar",
    path: "/fluorspar",
    meta: indexI6YuS0AXdnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/index.vue")
  },
  {
    name: "fluorspar-market-insights",
    path: "/fluorspar/market-insights",
    meta: market_45insightsnxgGrziInTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/market-insights.vue"),
    children: [
  {
    name: "fluorspar-market-insights-analysis",
    path: "analysis",
    meta: analysisAZoFVUMDZ5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/market-insights/analysis.vue")
  },
  {
    name: "fluorspar-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightszkP4z0x5EJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/market-insights/critical-insights.vue")
  },
  {
    name: "fluorspar-market-insights-videos",
    path: "videos",
    meta: videosXyqmLRhqgqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/market-insights/videos.vue")
  }
]
  },
  {
    name: "forecasts",
    path: "/forecasts",
    meta: forecastsWqQWc0MH9zMeta || {},
    component: () => import("/opt/buildhome/repo/pages/forecasts.vue")
  },
  {
    name: "forms-stellantis",
    path: "/forms/stellantis",
    component: () => import("/opt/buildhome/repo/pages/forms/stellantis.vue")
  },
  {
    name: "independent-practitioners-assurance-report",
    path: "/independent-practitioners-assurance-report",
    component: () => import("/opt/buildhome/repo/pages/independent-practitioners-assurance-report.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "industry-reports",
    path: "/industry-reports",
    component: () => import("/opt/buildhome/repo/pages/industry-reports.vue")
  },
  {
    name: "join-us",
    path: "/join-us",
    component: () => import("/opt/buildhome/repo/pages/join-us.vue")
  },
  {
    name: "lca",
    path: "/lca",
    component: () => import("/opt/buildhome/repo/pages/lca.vue")
  },
  {
    name: "lifetime-achievement-award-arnold-schwarzenegger",
    path: "/lifetime-achievement-award/arnold-schwarzenegger",
    component: () => import("/opt/buildhome/repo/pages/lifetime-achievement-award/arnold-schwarzenegger.vue")
  },
  {
    name: "lifetime-achievement-award",
    path: "/lifetime-achievement-award",
    component: () => import("/opt/buildhome/repo/pages/lifetime-achievement-award/index.vue")
  },
  {
    name: "lifetime-achievement-award-jb-straubel",
    path: "/lifetime-achievement-award/jb-straubel",
    component: () => import("/opt/buildhome/repo/pages/lifetime-achievement-award/jb-straubel.vue")
  },
  {
    name: "lifetime-achievement-award-stanley-whittingham",
    path: "/lifetime-achievement-award/stanley-whittingham",
    component: () => import("/opt/buildhome/repo/pages/lifetime-achievement-award/stanley-whittingham.vue")
  },
  {
    name: "lithium-ion-batteries-capacity-database",
    path: "/lithium-ion-batteries/capacity-database",
    meta: capacity_45database4XWL1IPNXRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database.vue"),
    children: [
  {
    name: "lithium-ion-batteries-capacity-database-gigafactory",
    path: "gigafactory",
    meta: gigafactory6R3k32eop2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database/gigafactory.vue")
  },
  {
    name: "lithium-ion-batteries-capacity-database-tier-ranking",
    path: "tier-ranking",
    meta: tier_45rankingp3i6be5PxEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database/tier-ranking.vue")
  }
]
  },
  {
    name: "lithium-ion-batteries-data-reports",
    path: "/lithium-ion-batteries/data-reports",
    meta: data_45reportslRnERUA3GbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports.vue"),
    children: [
  {
    name: "lithium-ion-batteries-data-reports-forecast",
    path: "forecast",
    meta: forecastqJqCCqGpboMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/forecast.vue")
  },
  {
    name: "lithium-ion-batteries-data-reports-market-assessments",
    path: "market-assessments",
    meta: market_45assessments1sIFH3OSZtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/market-assessments.vue")
  },
  {
    name: "lithium-ion-batteries-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentsemMc3ExvwzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/price-assessments.vue")
  }
]
  },
  {
    name: "lithium-ion-batteries",
    path: "/lithium-ion-batteries",
    meta: indexGxu7xHcGAPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/index.vue")
  },
  {
    name: "lithium-ion-batteries-lithium-ion-batteries-prices",
    path: "/lithium-ion-batteries/lithium-ion-batteries-prices",
    meta: lithium_45ion_45batteries_45prices3v8w7O8LfzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices.vue"),
    children: [
  {
    name: "lithium-ion-batteries-lithium-ion-batteries-prices-slug",
    path: ":slug()",
    meta: _91slug_93fYooskxAB4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/[slug].vue")
  },
  {
    name: "lithium-ion-batteries-lithium-ion-batteries-prices-chart",
    path: "chart",
    meta: chartrkMS17oaoqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/chart.vue")
  },
  {
    name: "lithium-ion-batteries-lithium-ion-batteries-prices-table",
    path: "table",
    meta: tableP98W82XPNCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/table.vue")
  }
]
  },
  {
    name: "lithium-ion-batteries-market-insights",
    path: "/lithium-ion-batteries/market-insights",
    meta: market_45insightsLPvjhaMAtVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights.vue"),
    children: [
  {
    name: "lithium-ion-batteries-market-insights-analysis",
    path: "analysis",
    meta: analysis6fx2ZqxOUFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/analysis.vue")
  },
  {
    name: "lithium-ion-batteries-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightszvjpko0IHVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/critical-insights.vue")
  },
  {
    name: "lithium-ion-batteries-market-insights-videos",
    path: "videos",
    meta: videosKpRBhaRWVsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/videos.vue")
  }
]
  },
  {
    name: "lithium-ion-battery-prices",
    path: "/lithium-ion-battery-prices",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-battery-prices.vue")
  },
  {
    name: "lithium-ion-battery-raw-material-index",
    path: "/lithium-ion-battery-raw-material-index",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-battery-raw-material-index.vue")
  },
  {
    name: "lithium-price-assessment-portal-slug",
    path: "/lithium-price-assessment-portal/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lithium-price-assessment-portal/[slug].vue")
  },
  {
    name: "lithium-prices",
    path: "/lithium-prices",
    component: () => import("/opt/buildhome/repo/pages/lithium-prices.vue")
  },
  {
    name: "lithium-slug",
    path: "/lithium/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lithium/[slug].vue")
  },
  {
    name: "lithium-data-reports",
    path: "/lithium/data-reports",
    meta: data_45reportsGxHknEN00zMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports.vue"),
    children: [
  {
    name: "lithium-data-reports-forecast",
    path: "forecast",
    meta: forecastRCq5T8YQ0kMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/forecast.vue")
  },
  {
    name: "lithium-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentszpc60HlSVUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/price-assessments.vue")
  },
  {
    name: "lithium-data-reports-sustainability",
    path: "sustainability",
    meta: sustainabilityVwkVBnnOwBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/sustainability.vue")
  }
]
  },
  {
    name: "lithium",
    path: "/lithium",
    meta: index9Oz1g1h8rZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/index.vue")
  },
  {
    name: "lithium-lithium-prices",
    path: "/lithium/lithium-prices",
    meta: lithium_45pricesoJNgJQtbnoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/lithium-prices.vue"),
    children: [
  {
    name: "lithium-lithium-prices-slug",
    path: ":slug()",
    meta: _91slug_93Y9YgWyRJY3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/lithium-prices/[slug].vue")
  },
  {
    name: "lithium-lithium-prices-chart",
    path: "chart",
    meta: chartNruAdaMsNJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/lithium-prices/chart.vue")
  },
  {
    name: "lithium-lithium-prices-price-customisation-tool",
    path: "price-customisation-tool",
    meta: price_45customisation_45toolgjhA0gnukAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/lithium-prices/price-customisation-tool.vue")
  },
  {
    name: "lithium-lithium-prices-table",
    path: "table",
    meta: tableEtZNuEenPYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/lithium-prices/table.vue")
  }
]
  },
  {
    name: "lithium-market-insights",
    path: "/lithium/market-insights",
    meta: market_45insightsVcRLh0H9oiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/market-insights.vue"),
    children: [
  {
    name: "lithium-market-insights-analysis",
    path: "analysis",
    meta: analysisiVuXCGhfM4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/market-insights/analysis.vue")
  },
  {
    name: "lithium-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insights0suklDEDAdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/market-insights/critical-insights.vue")
  },
  {
    name: "lithium-market-insights-videos",
    path: "videos",
    meta: videosK20aoQCX4aMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/market-insights/videos.vue")
  }
]
  },
  {
    name: "lithium-sustainability",
    path: "/lithium/sustainability",
    meta: sustainabilityyQOJaSn3N6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/sustainability.vue"),
    children: [
  {
    name: "lithium-sustainability-battery-emissions-analyser",
    path: "battery-emissions-analyser",
    meta: battery_45emissions_45analyserqHSE6GoEkyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/sustainability/battery-emissions-analyser.vue")
  },
  {
    name: "lithium-sustainability-lca",
    path: "lca",
    meta: lca1baWMwQ3l2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/sustainability/lca.vue")
  },
  {
    name: "lithium-sustainability-overview",
    path: "overview",
    meta: overview1BKLQL1obpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/sustainability/overview.vue")
  },
  {
    name: "lithium-sustainability-sustainability-index",
    path: "sustainability-index",
    meta: sustainability_45indexGd8iYM3BsIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/sustainability/sustainability-index.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "manganese-data-reports",
    path: "/manganese/data-reports",
    meta: data_45reportsHxU7ilY92WMeta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/data-reports.vue"),
    children: [
  {
    name: "manganese-data-reports-forecast",
    path: "forecast",
    meta: forecastSXWN7uIPa6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/data-reports/forecast.vue")
  }
]
  },
  {
    name: "manganese",
    path: "/manganese",
    meta: index54AsFA2d51Meta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/index.vue")
  },
  {
    name: "manganese-market-insights",
    path: "/manganese/market-insights",
    meta: market_45insights0DAIJSbDzHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/market-insights.vue"),
    children: [
  {
    name: "manganese-market-insights-analysis",
    path: "analysis",
    meta: analysis4cdAjG8uYrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/market-insights/analysis.vue")
  },
  {
    name: "manganese-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsnGiPn1kpC5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/market-insights/critical-insights.vue")
  },
  {
    name: "manganese-market-insights-videos",
    path: "videos",
    meta: videosURSHwX4Bp4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/market-insights/videos.vue")
  }
]
  },
  {
    name: "market-assessments",
    path: "/market-assessments",
    meta: market_45assessmentsLZygAaQAt2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/market-assessments.vue")
  },
  {
    name: "membership-slug",
    path: "/membership/:slug()",
    component: () => import("/opt/buildhome/repo/pages/membership/[slug].vue")
  },
  {
    name: "methodologies",
    path: "/methodologies",
    component: () => import("/opt/buildhome/repo/pages/methodologies/index.vue")
  },
  {
    name: "methodologies-market-assessments-gigafactories",
    path: "/methodologies/market-assessments/gigafactories",
    component: () => import("/opt/buildhome/repo/pages/methodologies/market-assessments/gigafactories.vue")
  },
  {
    name: "mineral-pricing-chart",
    path: "/mineral-pricing-chart",
    component: () => import("/opt/buildhome/repo/pages/mineral-pricing-chart.vue")
  },
  {
    name: "natural-graphite-slug",
    path: "/natural-graphite/:slug()",
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/[slug].vue")
  },
  {
    name: "natural-graphite-data-reports",
    path: "/natural-graphite/data-reports",
    meta: data_45reportslJRQJ51gCqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports.vue"),
    children: [
  {
    name: "natural-graphite-data-reports-forecast",
    path: "forecast",
    meta: forecastE0eKSUqgSPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/forecast.vue")
  },
  {
    name: "natural-graphite-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentspy1DrObgIQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/price-assessments.vue")
  },
  {
    name: "natural-graphite-data-reports-sustainability",
    path: "sustainability",
    meta: sustainability6RjQuZPTfNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/sustainability.vue")
  }
]
  },
  {
    name: "natural-graphite",
    path: "/natural-graphite",
    meta: indexeD23UhSKGEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/index.vue")
  },
  {
    name: "natural-graphite-market-insights",
    path: "/natural-graphite/market-insights",
    meta: market_45insightslFNS20MKUjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/market-insights.vue"),
    children: [
  {
    name: "natural-graphite-market-insights-analysis",
    path: "analysis",
    meta: analysissxHhPO6IVfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/market-insights/analysis.vue")
  },
  {
    name: "natural-graphite-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insights0hzmDC5RKEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/market-insights/critical-insights.vue")
  },
  {
    name: "natural-graphite-market-insights-videos",
    path: "videos",
    meta: videos7sjDB4ms8JMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/market-insights/videos.vue")
  }
]
  },
  {
    name: "natural-graphite-natural-graphite-prices",
    path: "/natural-graphite/natural-graphite-prices",
    meta: natural_45graphite_45pricessQl1MgpTw4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices.vue"),
    children: [
  {
    name: "natural-graphite-natural-graphite-prices-slug",
    path: ":slug()",
    meta: _91slug_936v79G5C5CoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/[slug].vue")
  },
  {
    name: "natural-graphite-natural-graphite-prices-chart",
    path: "chart",
    meta: chartJl4d9iMqQ5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/chart.vue")
  },
  {
    name: "natural-graphite-natural-graphite-prices-price-customisation-tool",
    path: "price-customisation-tool",
    meta: price_45customisation_45toolYRjhwMNB9FMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/price-customisation-tool.vue")
  },
  {
    name: "natural-graphite-natural-graphite-prices-table",
    path: "table",
    meta: tableSYS8ZHlfG9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/table.vue")
  }
]
  },
  {
    name: "natural-graphite-sustainability",
    path: "/natural-graphite/sustainability",
    meta: sustainabilityXbLOqMJbWnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/sustainability.vue"),
    children: [
  {
    name: "natural-graphite-sustainability-battery-emissions-analyser",
    path: "battery-emissions-analyser",
    meta: battery_45emissions_45analyserA0TmA3ToASMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/sustainability/battery-emissions-analyser.vue")
  },
  {
    name: "natural-graphite-sustainability-lca",
    path: "lca",
    meta: lcayJ93adE6EXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/sustainability/lca.vue")
  },
  {
    name: "natural-graphite-sustainability-overview",
    path: "overview",
    meta: overviewYPXHqoKrEvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/sustainability/overview.vue")
  },
  {
    name: "natural-graphite-sustainability-sustainability-index",
    path: "sustainability-index",
    meta: sustainability_45indexLG7UIoyba4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/sustainability/sustainability-index.vue")
  }
]
  },
  {
    name: "newsletter-signup",
    path: "/newsletter-signup",
    component: () => import("/opt/buildhome/repo/pages/newsletter-signup.vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    component: () => import("/opt/buildhome/repo/pages/newsletters.vue")
  },
  {
    name: "nickel-slug",
    path: "/nickel/:slug()",
    component: () => import("/opt/buildhome/repo/pages/nickel/[slug].vue")
  },
  {
    name: "nickel-data-reports",
    path: "/nickel/data-reports",
    meta: data_45reportsbKe6vcRWGEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports.vue"),
    children: [
  {
    name: "nickel-data-reports-forecast",
    path: "forecast",
    meta: forecastSc7LjxzPGHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/forecast.vue")
  },
  {
    name: "nickel-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentssSr9cu60pqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/price-assessments.vue")
  },
  {
    name: "nickel-data-reports-sustainability",
    path: "sustainability",
    meta: sustainabilityg5BOA4u2qwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/sustainability.vue")
  }
]
  },
  {
    name: "nickel",
    path: "/nickel",
    meta: indexkC9jVE9k93Meta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/index.vue")
  },
  {
    name: "nickel-market-insights",
    path: "/nickel/market-insights",
    meta: market_45insightsDKIOdgEtMnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/market-insights.vue"),
    children: [
  {
    name: "nickel-market-insights-analysis",
    path: "analysis",
    meta: analysisEy1ONyb1CgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/market-insights/analysis.vue")
  },
  {
    name: "nickel-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsxk7SZa2W9pMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/market-insights/critical-insights.vue")
  },
  {
    name: "nickel-market-insights-videos",
    path: "videos",
    meta: videos31rU4NR7HaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/market-insights/videos.vue")
  }
]
  },
  {
    name: "nickel-nickel-prices",
    path: "/nickel/nickel-prices",
    meta: nickel_45pricesb8FEzDSiQaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/nickel-prices.vue"),
    children: [
  {
    name: "nickel-nickel-prices-slug",
    path: ":slug()",
    meta: _91slug_93JCadwomZa0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/nickel-prices/[slug].vue")
  },
  {
    name: "nickel-nickel-prices-chart",
    path: "chart",
    meta: chartOUB5I7lkOQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/nickel-prices/chart.vue")
  },
  {
    name: "nickel-nickel-prices-price-customisation-tool",
    path: "price-customisation-tool",
    meta: price_45customisation_45toolbLB6luE5HXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/nickel-prices/price-customisation-tool.vue")
  },
  {
    name: "nickel-nickel-prices-table",
    path: "table",
    meta: table6KxgAeEgwFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/nickel-prices/table.vue")
  }
]
  },
  {
    name: "nickel-sustainability",
    path: "/nickel/sustainability",
    meta: sustainabilitywNFh9CgCZ8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/sustainability.vue"),
    children: [
  {
    name: "nickel-sustainability-battery-emissions-analyser",
    path: "battery-emissions-analyser",
    meta: battery_45emissions_45analyseruAqkfXGkW7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/sustainability/battery-emissions-analyser.vue")
  },
  {
    name: "nickel-sustainability-lca",
    path: "lca",
    meta: lca3QtffbfERRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/sustainability/lca.vue")
  },
  {
    name: "nickel-sustainability-overview",
    path: "overview",
    meta: overviewtHfr9CJVNNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/sustainability/overview.vue")
  },
  {
    name: "nickel-sustainability-sustainability-index",
    path: "sustainability-index",
    meta: sustainability_45indexToy4BiPrRLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/sustainability/sustainability-index.vue")
  }
]
  },
  {
    name: "phosphate-data-reports",
    path: "/phosphate/data-reports",
    meta: data_45reportsiUl62WbCYHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/data-reports.vue"),
    children: [
  {
    name: "phosphate-data-reports-forecast",
    path: "forecast",
    meta: forecastvVUVsoKjXQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/data-reports/forecast.vue")
  }
]
  },
  {
    name: "phosphate",
    path: "/phosphate",
    meta: indexKVnlTIdCXzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/index.vue")
  },
  {
    name: "phosphate-market-insights",
    path: "/phosphate/market-insights",
    meta: market_45insightsU2ZmCp9037Meta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/market-insights.vue"),
    children: [
  {
    name: "phosphate-market-insights-analysis",
    path: "analysis",
    meta: analysisaeLDp3ipaVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/market-insights/analysis.vue")
  },
  {
    name: "phosphate-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsBtA6lVihkGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/market-insights/critical-insights.vue")
  },
  {
    name: "phosphate-market-insights-videos",
    path: "videos",
    meta: videoswkSVxtmCNQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/market-insights/videos.vue")
  }
]
  },
  {
    name: "policy-newsletter",
    path: "/policy-newsletter",
    component: () => import("/opt/buildhome/repo/pages/policy-newsletter.vue")
  },
  {
    name: "preview-_postType-id",
    path: "/preview/_postType/:id()",
    meta: _91id_93h4oibBAr7rMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preview/_postType/[id].vue")
  },
  {
    name: "price-assessments-slug-id",
    path: "/price-assessments/:slug()/:id()",
    component: () => import("/opt/buildhome/repo/pages/price-assessments/[slug]/[id].vue")
  },
  {
    name: "price-assessments-slug-reports-id",
    path: "/price-assessments/:slug()/reports/:id()",
    component: () => import("/opt/buildhome/repo/pages/price-assessments/[slug]/reports/[id].vue")
  },
  {
    name: "price-assessments-all-prices-subscription",
    path: "/price-assessments/all-prices-subscription",
    component: () => import("/opt/buildhome/repo/pages/price-assessments/all-prices-subscription.vue")
  },
  {
    name: "price-assessments",
    path: "/price-assessments",
    meta: indexVnchHRinAMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-assessments/index.vue")
  },
  {
    name: "price-dashboard-chart-builder",
    path: "/price-dashboard/chart-builder",
    meta: chart_45builderuwfe2Hcji0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/chart-builder.vue")
  },
  {
    name: "price-dashboard",
    path: "/price-dashboard",
    meta: index8vsS0KUWJUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/index.vue")
  },
  {
    name: "price-dashboard-watchlist-id",
    path: "/price-dashboard/watchlist/:id()",
    meta: _91id_93CTA9r8Rz0oMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/watchlist/[id].vue")
  },
  {
    name: "price-dashboard-watchlist",
    path: "/price-dashboard/watchlist",
    meta: indexxwCInpLYwrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/watchlist/index.vue")
  },
  {
    name: "price-spotlight-signup",
    path: "/price-spotlight-signup",
    component: () => import("/opt/buildhome/repo/pages/price-spotlight-signup.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  },
  {
    name: "rare-earths-slug",
    path: "/rare-earths/:slug()",
    component: () => import("/opt/buildhome/repo/pages/rare-earths/[slug].vue")
  },
  {
    name: "rare-earths-data-reports",
    path: "/rare-earths/data-reports",
    meta: data_45reportswKxzGmHVzYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports.vue"),
    children: [
  {
    name: "rare-earths-data-reports-forecast",
    path: "forecast",
    meta: forecastzsmVRGfDMlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/forecast.vue")
  },
  {
    name: "rare-earths-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessments7mH8HSPsqWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/price-assessments.vue")
  },
  {
    name: "rare-earths-data-reports-sustainability",
    path: "sustainability",
    meta: sustainabilitygP19FglaXuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/sustainability.vue")
  }
]
  },
  {
    name: "rare-earths",
    path: "/rare-earths",
    meta: indexgEXpI3bsM6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/index.vue")
  },
  {
    name: "rare-earths-market-insights",
    path: "/rare-earths/market-insights",
    meta: market_45insightsp60zkdtGcUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/market-insights.vue"),
    children: [
  {
    name: "rare-earths-market-insights-analysis",
    path: "analysis",
    meta: analysisa55aJc5CPEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/market-insights/analysis.vue")
  },
  {
    name: "rare-earths-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsF3nDQYHkmVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/market-insights/critical-insights.vue")
  },
  {
    name: "rare-earths-market-insights-videos",
    path: "videos",
    meta: videosqZJSkFERSjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/market-insights/videos.vue")
  }
]
  },
  {
    name: "rare-earths-rare-earths-prices",
    path: "/rare-earths/rare-earths-prices",
    meta: rare_45earths_45pricespNHs2ElCduMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/rare-earths-prices.vue"),
    children: [
  {
    name: "rare-earths-rare-earths-prices-slug",
    path: ":slug()",
    meta: _91slug_93pABT8zOdoaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/[slug].vue")
  },
  {
    name: "rare-earths-rare-earths-prices-chart",
    path: "chart",
    meta: chartXlHhk0XmfLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/chart.vue")
  },
  {
    name: "rare-earths-rare-earths-prices-table",
    path: "table",
    meta: tablehpeyNxbRmiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/table.vue")
  }
]
  },
  {
    name: "rare-earths-sustainability",
    path: "/rare-earths/sustainability",
    meta: sustainabilityVPTINBZo2mMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/sustainability.vue"),
    children: [
  {
    name: "rare-earths-sustainability-lca",
    path: "lca",
    meta: lcaCJvnJzu3ZRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/sustainability/lca.vue")
  },
  {
    name: "rare-earths-sustainability-overview",
    path: "overview",
    meta: overviewU3D5Etjv8TMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/sustainability/overview.vue")
  },
  {
    name: "rare-earths-sustainability-sustainability-index",
    path: "sustainability-index",
    meta: sustainability_45indexztavm8KfbvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/sustainability/sustainability-index.vue")
  }
]
  },
  {
    name: "recycling-slug",
    path: "/recycling/:slug()",
    component: () => import("/opt/buildhome/repo/pages/recycling/[slug].vue")
  },
  {
    name: "recycling-black-mass-prices",
    path: "/recycling/black-mass-prices",
    meta: black_45mass_45pricesU2c9WJ3suFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/black-mass-prices.vue"),
    children: [
  {
    name: "recycling-black-mass-prices-slug",
    path: ":slug()",
    meta: _91slug_93JmLp2fvxo9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/black-mass-prices/[slug].vue")
  },
  {
    name: "recycling-black-mass-prices-chart",
    path: "chart",
    meta: chartu26Zl8F9BmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/black-mass-prices/chart.vue")
  },
  {
    name: "recycling-black-mass-prices-table",
    path: "table",
    meta: tableQScLVhPnKAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/black-mass-prices/table.vue")
  }
]
  },
  {
    name: "recycling-data-reports",
    path: "/recycling/data-reports",
    meta: data_45reports8ZfIHIp9JuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports.vue"),
    children: [
  {
    name: "recycling-data-reports-forecast",
    path: "forecast",
    meta: forecastVrumgZAXVcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports/forecast.vue")
  },
  {
    name: "recycling-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentsv2PXtTXFZCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports/price-assessments.vue")
  }
]
  },
  {
    name: "recycling",
    path: "/recycling",
    meta: indexYD3lNtJcwpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/index.vue")
  },
  {
    name: "recycling-market-insights",
    path: "/recycling/market-insights",
    meta: market_45insights59s9KIFxQnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/market-insights.vue"),
    children: [
  {
    name: "recycling-market-insights-analysis",
    path: "analysis",
    meta: analysisFoVq0pOYovMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/market-insights/analysis.vue")
  },
  {
    name: "recycling-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsKOIF7rCMaJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/market-insights/critical-insights.vue")
  },
  {
    name: "recycling-market-insights-videos",
    path: "videos",
    meta: videos1OCGWJMKdsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/market-insights/videos.vue")
  }
]
  },
  {
    name: "recycling-portal",
    path: "/recycling/portal",
    meta: portal1CTTUZ9t43Meta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/portal.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    component: () => import("/opt/buildhome/repo/pages/set-password.vue")
  },
  {
    name: "silicon-anode-data-reports",
    path: "/silicon-anode/data-reports",
    meta: data_45reportspIkQQG3nn3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/data-reports.vue"),
    children: [
  {
    name: "silicon-anode-data-reports-forecast",
    path: "forecast",
    meta: forecastDnImJHDPsvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/data-reports/forecast.vue")
  }
]
  },
  {
    name: "silicon-anode",
    path: "/silicon-anode",
    meta: indexSsQIUyPOObMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/index.vue")
  },
  {
    name: "silicon-anode-market-insights",
    path: "/silicon-anode/market-insights",
    meta: market_45insights8fLmpHcPTgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/market-insights.vue"),
    children: [
  {
    name: "silicon-anode-market-insights-analysis",
    path: "analysis",
    meta: analysisfFjDssdNyZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/market-insights/analysis.vue")
  },
  {
    name: "silicon-anode-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsPkzHn0MRUuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/market-insights/critical-insights.vue")
  },
  {
    name: "silicon-anode-market-insights-videos",
    path: "videos",
    meta: videos7ybE1CP7uzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/market-insights/videos.vue")
  }
]
  },
  {
    name: capacity_45databaseW9eQIa5j0OMeta?.name,
    path: "/sodium-ion-batteries/capacity-database",
    meta: capacity_45databaseW9eQIa5j0OMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database.vue"),
    children: [
  {
    name: "sodium-ion-batteries-capacity-database-anode",
    path: "anode",
    meta: anode1WABbiIJxoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/anode.vue")
  },
  {
    name: "sodium-ion-batteries-capacity-database-battery-cell",
    path: "battery-cell",
    meta: battery_45cellFluaAOUuneMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/battery-cell.vue")
  },
  {
    name: "sodium-ion-batteries-capacity-database-cathode",
    path: "cathode",
    meta: cathodeUZSTxVSGoiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/cathode.vue")
  },
  {
    name: "sodium-ion-batteries-capacity-database",
    path: "",
    meta: indexqdcseVYEWFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/index.vue")
  }
]
  },
  {
    name: "sodium-ion-batteries-data-reports",
    path: "/sodium-ion-batteries/data-reports",
    meta: data_45reportsBbGepCpdkrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports.vue"),
    children: [
  {
    name: "sodium-ion-batteries-data-reports-forecast",
    path: "forecast",
    meta: forecastPnD2vUj7Y2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/forecast.vue")
  },
  {
    name: "sodium-ion-batteries-data-reports-market-assessments",
    path: "market-assessments",
    meta: market_45assessments03AyLqIZjCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/market-assessments.vue")
  }
]
  },
  {
    name: "sodium-ion-batteries",
    path: "/sodium-ion-batteries",
    meta: indexnvEi1iGIDVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/index.vue")
  },
  {
    name: "sodium-ion-batteries-market-insights",
    path: "/sodium-ion-batteries/market-insights",
    meta: market_45insightsNblaA1GbJDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights.vue"),
    children: [
  {
    name: "sodium-ion-batteries-market-insights-analysis",
    path: "analysis",
    meta: analysismOE5565NneMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/analysis.vue")
  },
  {
    name: "sodium-ion-batteries-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsXQULMPNrKPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/critical-insights.vue")
  },
  {
    name: "sodium-ion-batteries-market-insights-videos",
    path: "videos",
    meta: videosku7vm3djuGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/videos.vue")
  }
]
  },
  {
    name: "solid-state-data-reports",
    path: "/solid-state/data-reports",
    meta: data_45reportsPTeiplxzoGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/data-reports.vue"),
    children: [
  {
    name: "solid-state-data-reports-forecast",
    path: "forecast",
    meta: forecastwx5MEX99CvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/data-reports/forecast.vue")
  }
]
  },
  {
    name: "solid-state",
    path: "/solid-state",
    meta: indexmQDcWUWQBFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/index.vue")
  },
  {
    name: "solid-state-market-insights",
    path: "/solid-state/market-insights",
    meta: market_45insightswK8e49bOVNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/market-insights.vue"),
    children: [
  {
    name: "solid-state-market-insights-analysis",
    path: "analysis",
    meta: analysisa4TItBCGNhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/market-insights/analysis.vue")
  },
  {
    name: "solid-state-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insightsXuq6pPi88WMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/market-insights/critical-insights.vue")
  },
  {
    name: "solid-state-market-insights-videos",
    path: "videos",
    meta: videospHi2ROIXHiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/market-insights/videos.vue")
  }
]
  },
  {
    name: "sustainability",
    path: "/sustainability",
    meta: sustainabilitym3n2ti7KvWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sustainability.vue")
  },
  {
    name: "synthetic-graphite-slug",
    path: "/synthetic-graphite/:slug()",
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/[slug].vue")
  },
  {
    name: "synthetic-graphite-data-reports",
    path: "/synthetic-graphite/data-reports",
    meta: data_45reportsHJ5JBqGcGIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports.vue"),
    children: [
  {
    name: "synthetic-graphite-data-reports-forecast",
    path: "forecast",
    meta: forecastNQlqrTF46QMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/forecast.vue")
  },
  {
    name: "synthetic-graphite-data-reports-price-assessments",
    path: "price-assessments",
    meta: price_45assessmentseUAR0Zc1Y0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/price-assessments.vue")
  },
  {
    name: "synthetic-graphite-data-reports-sustainability",
    path: "sustainability",
    meta: sustainabilityD0hZ2dYlxIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/sustainability.vue")
  }
]
  },
  {
    name: "synthetic-graphite",
    path: "/synthetic-graphite",
    meta: indexNhdCvu5I6TMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/index.vue")
  },
  {
    name: "synthetic-graphite-market-insights",
    path: "/synthetic-graphite/market-insights",
    meta: market_45insightsi47YgZZM2tMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/market-insights.vue"),
    children: [
  {
    name: "synthetic-graphite-market-insights-analysis",
    path: "analysis",
    meta: analysisyedzzVFySaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/market-insights/analysis.vue")
  },
  {
    name: "synthetic-graphite-market-insights-critical-insights",
    path: "critical-insights",
    meta: critical_45insights7vh1S7JKjlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/market-insights/critical-insights.vue")
  },
  {
    name: "synthetic-graphite-market-insights-videos",
    path: "videos",
    meta: videosq1W2Mhn5iYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/market-insights/videos.vue")
  }
]
  },
  {
    name: "synthetic-graphite-sustainability",
    path: "/synthetic-graphite/sustainability",
    meta: sustainabilityM8TtsstPdnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/sustainability.vue"),
    children: [
  {
    name: "synthetic-graphite-sustainability-battery-emissions-analyser",
    path: "battery-emissions-analyser",
    meta: battery_45emissions_45analyserGcYMdOSE4CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/sustainability/battery-emissions-analyser.vue")
  },
  {
    name: "synthetic-graphite-sustainability-lca",
    path: "lca",
    meta: lca8LcjoYSyjLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/sustainability/lca.vue")
  },
  {
    name: "synthetic-graphite-sustainability-overview",
    path: "overview",
    meta: overview4c4WOE4do1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/sustainability/overview.vue")
  },
  {
    name: "synthetic-graphite-sustainability-sustainability-index",
    path: "sustainability-index",
    meta: sustainability_45indexuOFd30DanuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/sustainability/sustainability-index.vue")
  }
]
  },
  {
    name: "synthetic-graphite-synthetic-graphite-prices",
    path: "/synthetic-graphite/synthetic-graphite-prices",
    meta: synthetic_45graphite_45pricesUBKrsVvzWDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices.vue"),
    children: [
  {
    name: "synthetic-graphite-synthetic-graphite-prices-slug",
    path: ":slug()",
    meta: _91slug_93oZyP7IPyVAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/[slug].vue")
  },
  {
    name: "synthetic-graphite-synthetic-graphite-prices-chart",
    path: "chart",
    meta: chartOF6PPOR8tjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/chart.vue")
  },
  {
    name: "synthetic-graphite-synthetic-graphite-prices-price-customisation-tool",
    path: "price-customisation-tool",
    meta: price_45customisation_45tool4o0mFaXBuvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/price-customisation-tool.vue")
  },
  {
    name: "synthetic-graphite-synthetic-graphite-prices-table",
    path: "table",
    meta: table1zqWvNLyH9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/table.vue")
  }
]
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "market-subCategory-video-by-id",
    path: "/:market/data-reports/:subCategory/video/:id",
    meta: _91id_93m3TjEmenjzMeta || {},
    component: () => import("~/custom-pages/[market]/data-reports/[subCategory]/video/[id].vue")
  },
  {
    name: "market-subCategory-report-by-id",
    path: "/:market/data-reports/:subCategory/report/:id",
    meta: _91id_93O2fD41Mkw6Meta || {},
    component: () => import("~/custom-pages/[market]/data-reports/[subCategory]/report/[id].vue")
  },
  {
    name: "market-report-by-id",
    path: "/:market/data-reports/report/:id",
    meta: _91id_93lpzqr6FtInMeta || {},
    component: () => import("~/custom-pages/[market]/data-reports/report/[id].vue")
  }
]