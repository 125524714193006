import { defineNuxtPlugin } from 'nuxt/app'
import { format, subYears } from 'date-fns'
import {
  findMatches,
  getAdaptedMembershipStatuses,
  getAdaptedPrices,
  getAdaptedTickerIndexes,
} from '~/utils/adapters'
import { subscriptionsQueryParamValues } from '~/constants/memberships'
import type {
  TChangePasswordPayload,
  TResetPasswordPayload,
  TResetPasswordResponse,
  TSetPasswordPayload,
} from '~/src/types/password'
import { useChartSidebarFilterStore } from '@/stores/chartSidebarFilter'
import type { TGeneral } from '~/src/types/common'
import type { TPriceRaw } from '~/src/types/prices-chart'

export default defineNuxtPlugin({
  name: 'restClient',
  setup(nuxtApp) {
    const { $httpClient, $config }: TGeneral = nuxtApp
    const { getAvailablePreselectedCombinations } = useChartSidebarFilterStore()
    const apiRestEndpoint = $config.public.apiRestEndpoint
    let controller = null

    const getPricesByProduct = async (
      product: string,
      params: TGeneral,
    ): Promise<TPriceRaw> => {
      if (controller) controller.abort()

      controller = new AbortController()

      const response = await $httpClient.post(
        `${apiRestEndpoint}/prices/v2/${product}`,
        params,
        { signal: controller.signal },
      )
      const combinations = getAvailablePreselectedCombinations(product)
      response.data = findMatches(response.data, combinations)
      return getAdaptedPrices(response)
    }

    /**
     * Get data for the price-assessments charts
     */
    const getMineralData = (params: TGeneral) => {
      return $httpClient.post(`${apiRestEndpoint}/mineral`, params)
    }

    /**
     * Get data for flake graphite pages
     */
    const getFlakeGraphiteData = () => {
      return $httpClient.post(`${apiRestEndpoint}/natural-graphite`)
    }

    /**
     * Get data for lithium ion battery price index page
     *
     * @returns
     */
    const getLithiumIonPriceData = () => {
      return $httpClient.post(`${apiRestEndpoint}/lithium-ion-pricing-data`)
    }

    /**
     * Change authenticated user password
     */
    const changePassword = (
      params: TChangePasswordPayload,
    ): TResetPasswordResponse => {
      return $httpClient.put(`${apiRestEndpoint}/password/change`, params)
    }

    /**
     * Request a token to reset the password
     */
    const resetPassword = (
      params: TResetPasswordPayload,
    ): TResetPasswordResponse => {
      return $httpClient.post(
        `${apiRestEndpoint}/password/reset-password`,
        params,
      )
    }

    /**
     * Set a new password using the token
     */
    const setPassword = (
      params: TSetPasswordPayload,
    ): TResetPasswordResponse => {
      return $httpClient.post(
        `${apiRestEndpoint}/password/set-password`,
        params,
      )
    }

    const getSubscriptions = async () => {
      const memberships = await $httpClient.post(
        `${apiRestEndpoint}/memberships/info?${subscriptionsQueryParamValues}`,
      )
      return getAdaptedMembershipStatuses(memberships)
    }

    const getTickerIndexes = async () => {
      return getAdaptedTickerIndexes(
        await $httpClient.post(`${apiRestEndpoint}/prices/v2/ticker`),
      )
    }

    /**
     * Get price data for mineral portal pages
     */
    const getPricesPortalByMineral = async (market: string) => {
      return $httpClient.post(
        `${apiRestEndpoint}/prices/v2/${market}/market-portal`,
        {
          to: format(new Date(), 'yyyy-MM-dd'),
          from: format(subYears(new Date(), 5), 'yyyy-MM-dd'),
        },
      )
    }

    /**
     * Get price index data for mineral portal pages
     */
    const getPriceIndexByMineral = async (mineral: string) => {
      const response = await $httpClient.post(
        `${apiRestEndpoint}/price-index/${mineral}`,
      )
      return response.data ?? null
    }

    /**
     * Get prices summary data for mineral portal pages
     */
    const getPricesConsolidatedByMineral = (market: string) => {
      return $httpClient.post(
        `${apiRestEndpoint}/prices/v2/${market}/consolidated`,
        {
          to: format(new Date(), 'yyyy-MM-dd'),
          from: format(subYears(new Date(), 5), 'yyyy-MM-dd'),
        },
      )
    }

    /**
     * Get Supply By Company for mineral portal pages
     */
    const getSupplyCompanyByMineral = (mineral: string) => {
      return $httpClient.post(
        `${apiRestEndpoint}/chemical-supply-by-company/${mineral}/portal`,
      )
    }

    /**
     * Get End Market for mineral portal pages
     */
    const getEndMarketByMineral = (mineral: string) => {
      return $httpClient.post(`${apiRestEndpoint}/end-market/${mineral}`)
    }

    /**
     * Get historical chemical for mineral portal pages
     */
    const getHistoricalChemicalByMineral = (mineral: string) => {
      return $httpClient.post(
        `${apiRestEndpoint}/chemical-supply-by-regions/${mineral}/portal`,
      )
    }

    /**
     * Get historical chemical for mineral portal pages
     */
    const getGlobalEVSalesByMineral = (mineral: string) => {
      return $httpClient.post(`${apiRestEndpoint}/ev-global-sales/${mineral}`)
    }

    /**
     * Get cathode demand for mineral portal pages
     */
    const getCathodeDemandByMineral = (mineral: string) => {
      return $httpClient.post(`${apiRestEndpoint}/demand-chemistry/${mineral}`)
    }

    const getCurrentMinesByMineral = (mineral: string) => {
      return $httpClient.post(`${apiRestEndpoint}/current-mines/${mineral}`)
    }

    const getEquityDataByMineral = (mineral: string) => {
      return $httpClient.post(
        `${apiRestEndpoint}/company-equity-data/${mineral}`,
      )
    }

    const getDatabaseSummaryByMineral = (mineral: string) => {
      return $httpClient.post(
        `${apiRestEndpoint}/database-page/${mineral}/summary`,
      )
    }

    const getReportDetailsById = async (
      category: string,
      id: string | number,
    ) => {
      return await $httpClient.post(
        `${apiRestEndpoint}/downloads/details/${category}/${id}`,
      )
    }

    const getSustainabilityIndexByProduct = async (
      market: string,
      type: string,
    ) => {
      if (!type || !market) return []
      return await $httpClient.post(
        `${apiRestEndpoint}/sustainability-index/${market}/${type}`,
      )
    }

    const getPricesCustomisationToolByProduct = async (product: string) => {
      return await $httpClient.post(
        `${apiRestEndpoint}/prices-customisation-tool/v2/${product}/assessments`,
      )
    }

    const getPricesCatalogByMineral = async (mineral: string) => {
      return await $httpClient.post(
        `${apiRestEndpoint}/prices/v2/${mineral}/catalog`,
      )
    }

    /**
     * Get data for the price-assessments charts for service pro
     */
    const getMarketPortal = (product: string, params: TGeneral) => {
      return $httpClient.post(
        `${apiRestEndpoint}/prices/v2/${product}/market-portal`,
        params,
      )
    }

    const getUserHubspot = () => {
      return $httpClient.post(`${apiRestEndpoint}/hubspot/user-identification`)
    }

    const getUserInfoHubspot = () => {
      return $httpClient.post(`${apiRestEndpoint}/hubspot/get-user-data`)
    }

    const setUserInfoHubspot = (params: TGeneral) => {
      return $httpClient.put(
        `${apiRestEndpoint}/hubspot/update-user-data`,
        params,
      )
    }

    const getAPIKey = () => {
      return $httpClient.post(`${apiRestEndpoint}/pro-api-management/get-key`)
    }

    const getVimeoVideoData = async (vimeoId: string | number) => {
      const response = await $httpClient.post(
        `${apiRestEndpoint}/vimeo/video/${vimeoId}`,
      )
      return response ?? {}
    }

    const getDailyIndexByProduct = (
      product: string,
      dateRange: { from: string; to: string },
    ) => {
      return $httpClient.post(
        `${apiRestEndpoint}/prices/v2/${product}/daily`,
        dateRange,
      )
    }

    return {
      provide: {
        restClient: {
          getMineralData,
          getLithiumIonPriceData,
          getFlakeGraphiteData,
          user: {
            changePassword,
            resetPassword,
            setPassword,
            getAPIKey,
          },
          getSubscriptions,
          getTickerIndexes,
          getHistoricalChemicalByMineral,
          getSupplyCompanyByMineral,
          getEndMarketByMineral,
          getGlobalEVSalesByMineral,
          getCathodeDemandByMineral,
          getCurrentMinesByMineral,
          getEquityDataByMineral,
          getPricesPortalByMineral,
          getPriceIndexByMineral,
          getPricesConsolidatedByMineral,
          getDatabaseSummaryByMineral,
          getPricesByProduct,
          getReportDetailsById,
          getSustainabilityIndexByProduct,
          getPricesCustomisationToolByProduct,
          getPricesCatalogByMineral,
          getMarketPortal,
          getUserHubspot,
          getUserInfoHubspot,
          setUserInfoHubspot,
          getDailyIndexByProduct,
          getVimeoVideoData,
        },
      },
    }
  },
})
