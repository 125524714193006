const LITHIUM_ROUTES = {
  '/lithium/portal': '/lithium',
  '/lithium/market-insights': '/lithium/market-insights/analysis',
  '/lithium/market-insights/news': '/lithium/market-insights/analysis',
  '/lithium/market-insights/price-assessment-analysis':
    '/lithium/market-insights/analysis',
  '/lithium/lithium-prices': '/lithium/lithium-prices/table',
  '/lithium/data-reports': '/lithium/data-reports/price-assessments',
  '/lithium/sustainability': '/lithium/sustainability/overview',
}

const COPPER_ROUTES = {
  '/copper/market-insights': '/copper/market-insights/analysis',
  '/copper/market-insights/news': '/copper/market-insights/analysis',
  '/copper/market-insights/price-assessment-analysis':
    '/copper/market-insights/analysis',
  '/copper/data-reports': '/copper/data-reports/forecast',
}

const COBALT_ROUTES = {
  '/cobalt/portal': '/cobalt',
  '/cobalt/market-insights': '/cobalt/market-insights/analysis',
  '/cobalt/market-insights/news': '/cobalt/market-insights/analysis',
  '/cobalt/market-insights/price-assessment-analysis':
    '/cobalt/market-insights/analysis',
  '/cobalt/cobalt-prices': '/cobalt/cobalt-prices/table',
  '/cobalt/data-reports': '/cobalt/data-reports/price-assessments',
  '/cobalt/sustainability': '/cobalt/sustainability/overview',
}

const NICKEL_ROUTES = {
  '/nickel/portal': '/nickel',
  '/nickel/market-insights': '/nickel/market-insights/analysis',
  '/nickel/market-insights/news': '/nickel/market-insights/analysis',
  '/nickel/market-insights/price-assessment-analysis':
    '/nickel/market-insights/analysis',
  '/nickel/nickel-prices': '/nickel/nickel-prices/table',
  '/nickel/data-reports': '/nickel/data-reports/price-assessments',
  '/nickel/sustainability': '/nickel/sustainability/overview',
}

const MANGANESE_ROUTES = {
  '/manganese/portal': '/manganese',
  '/manganese/market-insights': '/manganese/market-insights/analysis',
  '/manganese/market-insights/news': '/manganese/market-insights/analysis',
  '/manganese/market-insights/price-assessment-analysis':
    '/manganese/market-insights/analysis',
  '/manganese/data-reports': '/manganese/data-reports/forecast',
}

const NATURAL_GRAPHITE_ROUTES = {
  '/natural-graphite/portal': '/natural-graphite',
  '/natural-graphite/market-insights':
    '/natural-graphite/market-insights/analysis',
  '/natural-graphite/market-insights/news':
    '/natural-graphite/market-insights/analysis',
  '/natural-graphite/market-insights/price-assessment-analysis':
    '/natural-graphite/market-insights/analysis',
  '/natural-graphite/natural-graphite-prices':
    '/natural-graphite/natural-graphite-prices/table',
  '/natural-graphite/data-reports':
    '/natural-graphite/data-reports/price-assessments',
  '/natural-graphite/sustainability':
    '/natural-graphite/sustainability/overview',
}

const SYNTHETIC_GRAPHITE_ROUTES = {
  '/synthetic-graphite/portal': '/synthetic-graphite',
  '/synthetic-graphite/market-insights':
    '/synthetic-graphite/market-insights/analysis',
  '/synthetic-graphite/market-insights/news':
    '/synthetic-graphite/market-insights/analysis',
  '/synthetic-graphite/market-insights/price-assessment-analysis':
    '/synthetic-graphite/market-insights/analysis',
  '/synthetic-graphite/synthetic-graphite-prices':
    '/synthetic-graphite/synthetic-graphite-prices/table',
  '/synthetic-graphite/data-reports':
    '/synthetic-graphite/data-reports/price-assessments',
  '/synthetic-graphite/sustainability':
    '/synthetic-graphite/sustainability/overview',
}

const PHOSPHATE_ROUTES = {
  '/phosphate/portal': '/phosphate',
  '/phosphate/market-insights': '/phosphate/market-insights/analysis',
  '/phosphate/market-insights/news': '/phosphate/market-insights/analysis',
  '/phosphate/market-insights/price-assessment-analysis':
    '/phosphate/market-insights/analysis',
  '/phosphate/data-reports': '/phosphate/data-reports/forecast',
}

const FLUORSPAR_ROUTES = {
  '/fluorspar/portal': '/fluorspar',
  '/fluorspar/market-insights': '/fluorspar/market-insights/analysis',
  '/fluorspar/market-insights/news': '/fluorspar/market-insights/analysis',
  '/fluorspar/market-insights/price-assessment-analysis':
    '/fluorspar/market-insights/analysis',
  '/fluorspar/data-reports': '/fluorspar/data-reports/forecast',
}

const ANODE_ROUTES = {
  '/anode/portal': '/anode',
  '/anode/market-insights': '/anode/market-insights/analysis',
  '/anode/market-insights/news': '/anode/market-insights/analysis',
  '/anode/market-insights/price-assessment-analysis':
    '/anode/market-insights/analysis',
  '/anode/anode-prices': '/anode/anode-prices/table',
  '/anode/data-reports': '/anode/data-reports/price-assessments',
}

const CATHODE_ROUTES = {
  '/cathode/portal': '/cathode',
  '/cathode/market-insights': '/cathode/market-insights/analysis',
  '/cathode/market-insights/news': '/cathode/market-insights/analysis',
  '/cathode/market-insights/price-assessment-analysis':
    '/cathode/market-insights/analysis',
  '/cathode/cathode-prices': '/cathode/cathode-prices/table',
  '/cathode/data-reports': '/cathode/data-reports/price-assessments',
  '/cathode/capacity-database': '/cathode/capacity-database/cathode',
}

const SILICON_ANODE_ROUTES = {
  '/silicon-anode/portal': '/silicon-anode',
  '/silicon-anode/market-insights': '/silicon-anode/market-insights/analysis',
  '/silicon-anode/market-insights/news':
    '/silicon-anode/market-insights/analysis',
  '/silicon-anode/market-insights/price-assessment-analysis':
    '/silicon-anode/market-insights/analysis',
  '/silicon-anode/data-reports': '/silicon-anode/data-reports/forecast',
}

const LITHIUM_ION_BATTERIES_ROUTES = {
  '/lithium-ion-batteries/portal': '/lithium-ion-batteries',
  '/lithium-ion-batteries/market-insights':
    '/lithium-ion-batteries/market-insights/analysis',
  '/lithium-ion-batteries/market-insights/news':
    '/lithium-ion-batteries/market-insights/analysis',
  '/lithium-ion-batteries/market-insights/price-assessment-analysis':
    '/lithium-ion-batteries/market-insights/analysis',
  '/lithium-ion-batteries/lithium-ion-batteries-prices':
    '/lithium-ion-batteries/lithium-ion-batteries-prices/table',
  '/lithium-ion-batteries/data-reports':
    '/lithium-ion-batteries/data-reports/price-assessments',
  '/lithium-ion-batteries/capacity-database':
    '/lithium-ion-batteries/capacity-database/gigafactory',
}

const SODIUM_ION_BATTERIES_ROUTES = {
  '/sodium-ion-batteries/portal': '/sodium-ion-batteries',
  '/sodium-ion-batteries/market-insights':
    '/sodium-ion-batteries/market-insights/analysis',
  '/sodium-ion-batteries/market-insights/news':
    '/sodium-ion-batteries/market-insights/analysis',
  '/sodium-ion-batteries/market-insights/price-assessment-analysis':
    '/sodium-ion-batteries/market-insights/analysis',
  '/sodium-ion-batteries/data-reports':
    '/sodium-ion-batteries/data-reports/market-assessments',
}

const SOLID_STATE_BATTERIES_ROUTES = {
  '/solid-state/portal': '/solid-state',
  '/solid-state/market-insights': '/solid-state/market-insights/analysis',
  '/solid-state/market-insights/news': '/solid-state/market-insights/analysis',
  '/solid-state/market-insights/price-assessment-analysis':
    '/solid-state/market-insights/analysis',
  '/solid-state/data-reports': '/solid-state/data-reports/forecast',
}

const RECYCLING_ROUTES = {
  '/recycling/market-insights': '/recycling/market-insights/analysis',
  '/recycling/market-insights/news': '/recycling/market-insights/analysis',
  '/recycling/market-insights/price-assessment-analysis':
    '/recycling/market-insights/analysis',
  '/recycling/black-mass-prices': '/recycling/black-mass-prices/table',
  '/recycling/data-reports': '/recycling/data-reports/price-assessments',
}

const RARE_EARTHS_ROUTES = {
  '/rare-earths/portal': '/rare-earths',
  '/rare-earths/market-insights': '/rare-earths/market-insights/analysis',
  '/rare-earths/market-insights/news': '/rare-earths/market-insights/analysis',
  '/rare-earths/market-insights/price-assessment-analysis':
    '/rare-earths/market-insights/analysis',
  '/rare-earths/rare-earths-prices': '/rare-earths/rare-earths-prices/table',
  '/rare-earths/data-reports': '/rare-earths/data-reports/price-assessments',
  '/rare-earths/sustainability': '/rare-earths/sustainability/overview',
}

const OLD_MARKETS = {
  '/market/cobalt/cobalt-prices/table': '/cobalt/cobalt-prices/table',
  '/market/fluorspar/analysis': '/fluorspar',
  '/market/fluorspar/reports': '/fluorspar/data-reports/forecasts',
  '/market/manganese/analysis': '/manganese',
  '/market/manganese/reports': '/manganese/data-reports/forecasts',
  '/market/phosphate/analysis': '/phosphate',
  '/market/phosphate/reports': '/phosphate/data-reports/forecast',
  '/market/rare-earths/market-insights': '/rare-earths/market-insights',
  '/market/silicon-anode/reports': '/silicon-anode/data-reports/forecast',
  '/market/silicon-anode/analysis': '/silicon-anode',
  '/market/solid-state/analysis': '/solid-state',
  '/market/lithium/price-customisation-tool':
    '/lithium/lithium-prices/price-customisation-tool',
  '/market/anode/capacity-database': '/anode/capacity-database',
  '/market/cobalt/sustainability': '/cobalt/sustainability',
  '/market/lithium-ion-batteries/capacity-database':
    '/lithium-ion-batteries/capacity-database',
  '/market/lithium-ion-batteries/capacity-database/tier-ranking':
    '/lithium-ion-batteries/capacity-database/tier-ranking',
  '/market/lithium-ion-battery-raw-material-index':
    '/lithium-ion-battery-raw-material-index',
  '/market/lithium/chart': '/lithium/chart',
  '/market/lithium/analysis': '/lithium/market-insights/analysis',
  '/market/lithium/market-insights': '/lithium/market-insights',
  '/market/lithium//price-customisation-tool':
    '/lithium/lithium-prices/price-customisation-tool',
  '/market/recycling/sustainability': '/recycling/sustainability',
  '/market/lithium/sustainability': '/lithium/sustainability',
  '/market/natural-graphite/market-insights':
    '/natural-graphite/market-insights',
  '/market/natural-graphite/sustainability': '/natural-graphite/sustainability',
  '/market/nickel/market-insights': '/nickel/market-insights',
  '/market/price-dashboard/watchlist': '/price-dashboard/watchlist',
  '/market/rare-earths/testing-analysis': '/rare-earths/testing-analysis',
  '/market/rare-earths/sustainability': '/rare-earths/sustainability',
  '/market/recycling/market-insights': '/recycling/market-insights',
  '/market/sodium-ion-batteries/capacity-database':
    '/sodium-ion-batteries/capacity-database',
  '/market/synthetic-graphite/sustainability':
    '/synthetic-graphite/sustainability',
  '/market/nickel/sustainability': '/nickel/sustainability',
  '/market/cobalt/drc-and-china-strengthen-ties-following-beijing-visit-benchmark-cobalt':
    '/cobalt/drc-and-china-strengthen-ties-following-beijing-visit-benchmark-cobalt',
  '/market/cobalt/erg-copper-and-cobalt-mining-operation-put-on-hold-following-waste-leak-benchmark-cobalt':
    '/cobalt/erg-copper-and-cobalt-mining-operation-put-on-hold-following-waste-leak-benchmark-cobalt',
  '/market/cobalt/eu-delegation-to-visit-drc-benchmark-cobalt':
    '/cobalt/eu-delegation-to-visit-drc-benchmark-cobalt',
  '/market/cobalt/jervois-to-receive-eur-12m-13m-grant-for-expansion-of-cobalt-refinery-in-finland-benchmark-cobalt':
    '/cobalt/jervois-to-receive-eur-12m-13m-grant-for-expansion-of-cobalt-refinery-in-finland-benchmark-cobalt',
  '/market/cobalt/jervois-to-receive-us-department-of-defense-funding-to-reinforce-us-cobalt-supply-chain-benchmark-cobalt':
    '/cobalt/jervois-to-receive-us-department-of-defense-funding-to-reinforce-us-cobalt-supply-chain-benchmark-cobalt',
  '/market/cobalt/stellantis-signs-offtake-deal-with-kuniko-for-supply-of-norwegian-cobalt-sulphate-and-nickel-sulphate-benchmark-cobalt':
    '/cobalt/stellantis-signs-offtake-deal-with-kuniko-for-supply-of-norwegian-cobalt-sulphate-and-nickel-sulphate-benchmark-cobalt',
  '/market/nickel/cngr-and-posco-enters-jv-to-produce-50000-tpa-market/nickel-sulphate-and-110000-tpa-precursor-material-in-south-korea-benchmark-market/nickel':
    '/nickel/cngr-and-posco-enters-jv-to-produce-50000-tpa-market/nickel-sulphate-and-110000-tpa-precursor-material-in-south-korea-benchmark-market/nickel',
  '/market/nickel/fire-at-indonesias-5th-largest-market/nickel-smelter-jiangsu-delong-market/nickels-gunbuster-1-dead-benchmark-market/nickel':
    '/nickel/fire-at-indonesias-5th-largest-market/nickel-smelter-jiangsu-delong-market/nickels-gunbuster-1-dead-benchmark-market/nickel',
  '/market/nickel/huayou-cobalt-commences-construction-at-materials-project-in-zhejiang-china-benchmark-market/nickel':
    '/nickel/huayou-cobalt-commences-construction-at-materials-project-in-zhejiang-china-benchmark-market/nickel',
  '/market/nickel/lme-expecting-new-applications-following-huayou-listing-benchmark-market/nickel':
    '/nickel/lme-expecting-new-applications-following-huayou-listing-benchmark-market/nickel',
  '/market/nickel/philippines-announces-further-chinese-investment-in-the-development-of-ev-battery-plant-benchmark-market/nickel':
    '/nickel/philippines-announces-further-chinese-investment-in-the-development-of-ev-battery-plant-benchmark-market/nickel',
  '/market/nickel/the-lme-is-set-to-improve-stock-reporting-transparency-benchmark-market/nickel':
    '/nickel/the-lme-is-set-to-improve-stock-reporting-transparency-benchmark-market/nickel',
  '/market/synthetic-graphite/chinese-anode-producers-announce-new-market/synthetic-graphite-anode-capacity-benchmark-market/synthetic-graphite':
    '/synthetic-graphite/chinese-anode-producers-announce-new-market/synthetic-graphite-anode-capacity-benchmark-market/synthetic-graphite',
  '/market/synthetic-graphite/chinese-companies-announce-coke-project-updates-benchmark-market/synthetic-graphite':
    '/synthetic-graphite/chinese-companies-announce-coke-project-updates-benchmark-market/synthetic-graphite',
  '/market/synthetic-graphite/chinese-ev-sector-continues-recovery-in-may-but-steel-market-remains-sluggish-benchmark-market/synthetic-graphite':
    '/synthetic-graphite/chinese-ev-sector-continues-recovery-in-may-but-steel-market-remains-sluggish-benchmark-market/synthetic-graphite',
  '/market/synthetic-graphite/epsilon-announce-plans-to-enter-us-anode-market-benchmark-market/synthetic-graphite':
    '/synthetic-graphite/epsilon-announce-plans-to-enter-us-anode-market-benchmark-market/synthetic-graphite',
  '/market/synthetic-graphite/higher-sulphur-cokes-grow-market-share-benchmark-market/synthetic-graphite':
    '/synthetic-graphite/higher-sulphur-cokes-grow-market-share-benchmark-market/synthetic-graphite',
  '/market/synthetic-graphite/taqat-development-and-chevron-lummus-global-announce-plans-to-construct-first-pet-needle-coke-facility-in-saudi-arabia-benchmark-market/synthetic-graphite':
    '/synthetic-graphite/taqat-development-and-chevron-lummus-global-announce-plans-to-construct-first-pet-needle-coke-facility-in-saudi-arabia-benchmark-market/synthetic-graphite',
  '/market/cobalt/data-reports': '/cobalt/data-reports/forecast',
  '/market/anode': '/anode',
  '/market/anode/anode-prices': '/anode/anode-prices',
  '/market/anode/data-reports': '/anode/data-reports',
  '/market/anode/data-reports/?tab=forecast':
    '/anode/data-reports/?tab=forecast',
  '/market/cathode/capacity-database': '/cathode/capacity-database',
  '/market/anode/market-insights': '/anode/market-insights',
  '/market/anode/portal': '/anode/portal',
  '/market/cathode': '/cathode',
  '/market/cathode/cathode-prices': '/cathode/cathode-prices',
  '/market/cobalt/sustainability/?tab=overview':
    '/cobalt/sustainability/?tab=overview',
  '/market/cobalt/sustainability/?tab=sustainability-index':
    '/cobalt/sustainability/?tab=sustainability-index',
  '/market/fluorspar/portal': '/fluorspar',
  '/market/fluorspar/data-reports': '/fluorspar/data-reports/news',
  '/market/fluorspar/data-reports/?tab=forecast':
    '/fluorspar/data-reports/?tab=forecast',
  '/market/fluorspar/market-insights': '/fluorspar/market-insights',
  '/market/fluorspar/market-insights/news':
    '/fluorspar/market-insights/analysis',
  '/market/cobalt/market-insights': '/cobalt/market-insights',
  '/market/cobalt/market-insights/news': '/cobalt/market-insights/analysis',
  '/market/cathode/data-reports': '/cathode/data-reports',
  '/market/cathode/market-insights': '/cathode/market-insights',
  '/market/cathode/market-insights/news': '/cathode/market-insights/analysis',
  '/market/cathode/portal': '/cathode/portal',
  '/market/cobalt': '/cobalt',
  '/market/cobalt/cobalt-prices': '/cobalt/cobalt-prices',
  '/market/cobalt/prices': '/cobalt/prices',
  '/market/cobalt/data-reports/?tab=forecast':
    '/cobalt/data-reports/?tab=forecast',
  '/market/cobalt/portal': '/cobalt/portal',
  '/market/lithium-ion-batteries': '/lithium-ion-batteries',
  '/market/lithium-ion-batteries/data-reports/?tab=forecast':
    '/lithium-ion-batteries/data-reports/?tab=forecast',
  '/market/lithium-ion-batteries/data-reports/market-assessments':
    '/lithium-ion-batteries/data-reports/market-assessments',
  '/market/lithium-ion-batteries/data-reports':
    '/lithium-ion-batteries/data-reports',
  '/market/lithium-ion-batteries/lithium-ion-batteries-prices':
    '/lithium-ion-batteries/lithium-ion-batteries-prices',
  '/market/lithium-ion-batteries/market-insights':
    '/lithium-ion-batteries/market-insights',
  '/market/lithium-ion-batteries/market-insights/news':
    '/lithium-ion-batteries/market-insights/analysis',
  '/market/lithium-ion-batteries/portal': '/lithium-ion-batteries/portal',
  '/market/lithium': '/lithium',
  '/market/lithium/data-reports/?tab=forecast':
    '/lithium/data-reports/?tab=forecast',
  '/market/lithium/data-reports': '/lithium/data-reports',
  '/market/lithium/lithium-prices': '/lithium/lithium-prices',
  '/market/lithium/prices': '/lithium/prices',
  '/market//lithium/market-insights/news': '//lithium/market-insights/analysis',
  '/market/lithium/portal': '/lithium/portal',
  '/market/lithium/sustainability/?tab=overview':
    '/lithium/sustainability/?tab=overview',
  '/market/lithium/sustainability/?tab=sustainability-index':
    '/lithium/sustainability/?tab=sustainability-index',
  '/market/manganese': '/manganese',
  '/market/manganese/data-reports': '/manganese/data-reports',
  '/market/manganese/data-reports/?tab=forecast':
    '/manganese/data-reports/?tab=forecast',
  '/market/manganese/market-insights': '/manganese/market-insights',
  '/market/manganese/portal': '/manganese/portal',
  '/market/natural-graphite': '/natural-graphite',
  '/market/natural-graphite/data-reports': '/natural-graphite/data-reports',
  '/market/natural-graphite/natural-graphite-prices':
    '/natural-graphite/natural-graphite-prices',
  '/market/natural-graphite/portal': '/natural-graphite/portal',
  '/market/nickel': '/nickel',
  '/market/nickel/data-reports/?tab=forecast':
    '/nickel/data-reports/?tab=forecast',
  '/market/nickel/data-reports': '/nickel/data-reports',
  '/market/nickel/nickel-prices': '/nickel/nickel-prices',
  '/market/nickel/portal': '/nickel/portal',
  '/market/nickel/sustainability/?tab=overview':
    '/nickel/sustainability/?tab=overview',
  '/market/nickel/sustainability/?tab=sustainability-index':
    '/nickel/sustainability/?tab=sustainability-index',
  '/market/phosphate': '/phosphate',
  '/market/phosphate/data-reports': '/phosphate/data-reports',
  '/market/phosphate/data-reports/?tab=forecast':
    '/phosphate/data-reports/?tab=forecast',
  '/market/phosphate/market-insights': '/phosphate/market-insights',
  '/market/phosphate/portal': '/phosphate/portal',
  '/market/price-dashboard': '/price-dashboard',
  '/market/price-dashboard/chart-builder': '/price-dashboard/chart-builder',
  '/market/rare-earths': '/rare-earths',
  '/market/rare-earths/data-reports/?tab=forecast':
    '/rare-earths/data-reports/?tab=forecast',
  '/market/rare-earths/data-reports': '/rare-earths/data-reports',
  '/market/rare-earths/portal': '/rare-earths/portal',
  '/market/rare-earths/rare-earths-prices': '/rare-earths/rare-earths-prices',
  '/market/rare-earths/sustainability/?tab=overview':
    '/rare-earths/sustainability/?tab=overview',
  '/market/recycling/portal': '/recycling/portal',
  '/market/recycling/data-reports/?tab=forecast':
    '/recycling/data-reports/?tab=forecast',
  '/market/recycling/data-reports': '/recycling/data-reports',
  '/market/recycling/market-insights/news':
    '/recycling/market-insights/analysis',
  '/market/recycling/prices': '/recycling/prices',
  '/market/recycling/recycling-prices': '/recycling/black-mass-prices',
  '/market/silicon-anode': '/silicon-anode',
  '/market/silicon-anode/data-reports': '/silicon-anode/data-reports',
  '/market/silicon-anode/data-reports/?tab=forecast':
    '/silicon-anode/data-reports/?tab=forecast',
  '/market/silicon-anode/market-insights': '/silicon-anode/market-insights',
  '/market/silicon-anode/portal': '/silicon-anode/portal',
  '/market/sodium-ion-batteries': '/sodium-ion-batteries',
  '/market/sodium-ion-batteries/data-reports':
    '/sodium-ion-batteries/data-reports',
  '/market/sodium-ion-batteries/market-insights':
    '/sodium-ion-batteries/market-insights',
  '/market/sodium-ion-batteries/portal': '/sodium-ion-batteries/portal',
  '/market/solid-state/data-reports': '/solid-state/data-reports',
  '/market/solid-state/data-reports/?tab=forecast':
    '/solid-state/data-reports/?tab=forecast',
  '/market/solid-state/reports': '/solid-state/data-reports/forecast',
  '/market/solid-state/market-insights': '/solid-state/market-insights',
  '/market/solid-state/portal': '/solid-state/portal',
  '/market/synthetic-graphite/data-reports/?tab=forecast':
    '/synthetic-graphite/data-reports/?tab=forecast',
  '/market/synthetic-graphite/data-reports': '/synthetic-graphite/data-reports',
  '/market/synthetic-graphite/market-insights':
    '/synthetic-graphite/market-insights',
  '/market/synthetic-graphite/market-insights/news':
    '/synthetic-graphite/market-insights/analysis',
  '/market/synthetic-graphite/portal': '/synthetic-graphite/portal',
  '/market/synthetic-graphite/sustainability/?tab=overview':
    '/synthetic-graphite/sustainability/?tab=overview',
  '/market/synthetic-graphite/synthetic-graphite-prices':
    '/synthetic-graphite/synthetic-graphite-prices',
  '/services/price-assessments': '/price-assessments',
  '/services/market-assessments': '/market-assessments',
  '/services/lca': '/lca',
  '/services/forecasts': '/forecasts',
  '/services/esg': '/sustainability',
}

export default {
  ...LITHIUM_ROUTES,
  ...COBALT_ROUTES,
  ...NICKEL_ROUTES,
  ...MANGANESE_ROUTES,
  ...NATURAL_GRAPHITE_ROUTES,
  ...SYNTHETIC_GRAPHITE_ROUTES,
  ...PHOSPHATE_ROUTES,
  ...FLUORSPAR_ROUTES,
  ...ANODE_ROUTES,
  ...CATHODE_ROUTES,
  ...SILICON_ANODE_ROUTES,
  ...LITHIUM_ION_BATTERIES_ROUTES,
  ...SODIUM_ION_BATTERIES_ROUTES,
  ...SOLID_STATE_BATTERIES_ROUTES,
  ...RECYCLING_ROUTES,
  ...RARE_EARTHS_ROUTES,
  ...COPPER_ROUTES,
  ...OLD_MARKETS,
}
